import {
  FDR_BG_COLOR_MAPPING,
  FDR_TEXT_COLOR_MAPPING
} from 'utils/globalSettings'
import Box from '@mui/material/Box'

export default function PlayerFixture ({ gameweek, playerData }) {
  const playerFixtures = playerData.fixtures
  const playerGameweekFixtures = playerFixtures.filter(
    fixture => fixture.gameweek_num === gameweek
  )
  // const fixtureName = `${playerFixture.team} (${playerFixture.home_away})`
  // const xp = playerFixture.xP
  // const fdr = playerFixture.fdr
  // const bgColor = FDR_BG_COLOR_MAPPING[parseInt(fdr)]
  // const textColor = FDR_TEXT_COLOR_MAPPING[parseInt(fdr)]

  return (
    <Box style={{ display: 'flex', flexDirection: 'row', gap: '0.5em' }}>
      {playerGameweekFixtures.map(playerFixture => (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: FDR_BG_COLOR_MAPPING[parseInt(playerFixture.fdr)],
            color: FDR_TEXT_COLOR_MAPPING[parseInt(playerFixture.fdr)],
            padding: '4px',
            borderRadius: '0.5em',
            textAlign: 'center',
            textWrap: 'nowrap',
            width: '100%'
          }}
        >
          <div
            style={{ fontSize: { xs: '8px', sm: '12px', md: '12px' } }}
          >{`${playerFixture.team} (${playerFixture.home_away})`}</div>
          <div style={{ fontSize: { xs: '8px', sm: '12px', md: '12px' } }}>
            {playerFixture.xP}
          </div>
        </Box>
      ))}
    </Box>
  )
}
