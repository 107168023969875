import './PlayerBox.css'
import PlayerJersey from './PlayerJersey'
import PlayerName from './PlayerName'
import PlayerStat from './PlayerStat'
import Box from '@mui/material/Box'
import Fixtures from './Fixtures'
import ExpectedPointsNextGameweeks from './ExpectedPointsNextGameweeks'
import Form from './Form'
import grey from '@mui/material/colors/grey'
import PlayerMenu from './PlayerMenu'
import Icon from '@mdi/react'
import { mdiAlphaCCircle } from '@mdi/js'
import { mdiAlphaVCircle } from '@mdi/js'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import yellow from '@mui/material/colors/yellow'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import axiosService from 'utils/axios'
import { ROUTES } from 'utils/globalSettings'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { useState } from 'react'
import CONFIG from 'config.json'

export default function PlayerBox ({ playerInfo, size }) {
  const {
    highlightedPlayerIds,
    setHighlightedPlayerIds,
    playerSubstituteCandidateId,
    setPlayerSubstituteCandidateId,
    setSelectedDraft,
    selectedDraftCaptainPositionId,
    selectedDraftViceCaptainPositionId,
    playerInfoRadioValue,
    positionIdToTransfer,
    originalPlayersData,
    selectedDraftId,
    selectedDraft
  } = useDraftsSharedState()

  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))
  const isMd = useMediaQuery(theme.breakpoints.only('md'))
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: '',
    severity: ''
  })
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }
  // Determine icon size based on the screen size
  const iconSize = isXs ? 0.7 : isSm ? 0.8 : isMd ? 1 : 1
  const handleSubstitution = event => {
    // event.stopPropagation() // Stop event propagation to document

    setSelectedDraft(prevDraftTeam => {
      const newDraftTeam = { ...prevDraftTeam }

      const firstPlayerIndex = newDraftTeam.players.findIndex(
        player => player.position_id === playerSubstituteCandidateId
      )
      const secondPlayerIndex = newDraftTeam.players.findIndex(
        player => player.position_id === playerInfo.position_id
      )

      if (firstPlayerIndex !== -1 && secondPlayerIndex !== -1) {
        // change inMainTeam to true if false and vice versa of both players

        const firstPlayerInMainTeam =
          newDraftTeam.players[firstPlayerIndex].in_main_team
        const secondPlayerInMainTeam =
          newDraftTeam.players[secondPlayerIndex].in_main_team
        newDraftTeam.players[firstPlayerIndex].in_main_team =
          secondPlayerInMainTeam

        newDraftTeam.players[secondPlayerIndex].in_main_team =
          firstPlayerInMainTeam

        newDraftTeam.players[firstPlayerIndex].position_id =
          playerInfo.position_id
        newDraftTeam.players[secondPlayerIndex].position_id =
          playerSubstituteCandidateId
        newDraftTeam.players.sort(
          (player1, player2) => player1.position_id - player2.position_id
        )
      }

      setPlayerSubstituteCandidateId(null) // Clear the candidate after substitution
      setHighlightedPlayerIds([])
      return newDraftTeam
    })
    axiosService
      .put(ROUTES.saveDraft + selectedDraftId + '/', selectedDraft)
      .then(res => {
        setSnackbarMessage({
          message: 'Draft saved!',
          severity: 'success'
        })
        setSnackbarOpen(true)
      })
  }

  return (
    <Box
      sx={{
        paddingTop: '0.1em',
        paddingBottom: '0.1em',
        width: {
          xs: size === 'small' ? '70px' : size === 'medium' ? '88px' : '90px',
          sm:
            size === 'small' ? '140px' : size === 'medium' ? '150px' : '160px',
          md: size === 'small' ? '150px' : size === 'medium' ? '180px' : '180px'
        },
        height: { xs: '110px', sm: '134px', md: '160px' }
      }}
    >
      <Box
        className={`PlayerBox ${
          highlightedPlayerIds.includes(playerInfo.position_id)
            ? 'highlight'
            : ''
        }`}
        alignItems='center'
        onClick={
          highlightedPlayerIds.includes(playerInfo.position_id)
            ? handleSubstitution
            : null
        }
        sx={{
          cursor: highlightedPlayerIds.includes(playerInfo.position_id)
            ? 'pointer'
            : 'default',
          display: 'flex',
          flexDirection: 'column',
          margin: { xs: '0.05em 0.05em', sm: '0.1em 0.1em', md: '0.2em 0.2em' },
          padding: { xs: '0.05em', sm: '0.1em', md: '0.1em' },
          border: `1.5px solid`,
          borderColor: highlightedPlayerIds.includes(playerInfo.position_id)
            ? yellow
            : grey[700],
          backgroundColor: highlightedPlayerIds.includes(playerInfo.position_id)
            ? yellow[100]
            : playerInfo.position_id === positionIdToTransfer
            ? yellow[300]
            : playerInfo.position_id === playerSubstituteCandidateId
            ? yellow[300]
            : grey[400],
          borderRadius: '0.5em'
          // opacity: 0.98
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center', // Center the jersey and menu horizontally
            alignItems: 'center',
            width: '100%',
            position: 'relative' // To allow absolute positioning inside this box,
          }}
        >
          {playerInfo.id &&
            playerInfo.position_id === selectedDraftCaptainPositionId && (
              <Box
                sx={{
                  position: 'absolute',
                  left: '0.05em',
                  top: '0.2em'
                }}
              >
                <Icon
                  path={mdiAlphaCCircle}
                  size={iconSize}
                  color={grey[900]}
                />
              </Box>
            )}
          {playerInfo.id &&
            playerInfo.position_id === selectedDraftViceCaptainPositionId && (
              <Box
                sx={{
                  position: 'absolute',
                  left: '0.05em',
                  top: '0.2em'
                }}
              >
                <Icon
                  path={mdiAlphaVCircle}
                  size={iconSize}
                  color={grey[900]}
                />
              </Box>
            )}

          <PlayerJersey playerInfo={playerInfo} />
          {
            <Box
              sx={{
                position: 'absolute',
                right: '-0.4em',
                top: '-0.3em'
              }}
            >
              <PlayerMenu playerInfo={playerInfo} />
            </Box>
          }
        </Box>
        <PlayerName playerInfo={playerInfo} size={size} />

        {(playerInfoRadioValue === 'now_cost' ||
          playerInfoRadioValue === 'selected_by_percent' ||
          playerInfoRadioValue === 'xp_next_3_gw_total' ||
          playerInfoRadioValue === 'xp_next_5_gw_total') && (
          <PlayerStat
            valueToDisplay={
              originalPlayersData.filter(
                player => player.player_id === playerInfo.id
              )[0][playerInfoRadioValue]
            }
            statKey={playerInfoRadioValue}
          />
        )}

        {playerInfoRadioValue === 'position' && (
          <PlayerStat
            valueToDisplay={playerInfo.id !== null ? playerInfo.position : null}
          />
        )}

        {playerInfoRadioValue === 'team' && (
          <PlayerStat valueToDisplay={playerInfo.team} />
        )}

        {playerInfoRadioValue === 'form' && playerInfo.id && (
          <Form
            form={
              originalPlayersData.filter(
                player => player.player_id === playerInfo.id
              )[0].form_array
            }
            size={size}
          />
        )}

        {playerInfoRadioValue === 'fixtures' && playerInfo.id && (
          <Fixtures
            fixtures={
              originalPlayersData.filter(
                player => player.player_id === playerInfo.id
              )[0]?.fixtures
            }
            size={size}
          />
        )}

        {playerInfoRadioValue === 'xp_next_3_gws' && playerInfo.id && (
          <ExpectedPointsNextGameweeks
            fixtures={
              originalPlayersData.filter(
                player => player.player_id === playerInfo.id
              )[0]?.fixtures
            }
            numOfGameweeks={3}
            size={size}
          />
        )}

        {playerInfoRadioValue === 'xp_next_gw' && playerInfo.id && (
          <ExpectedPointsNextGameweeks
            fixtures={
              originalPlayersData.filter(
                player => player.player_id === playerInfo.id
              )[0].fixtures
            }
            numOfGameweeks={1}
          />
        )}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={CONFIG['snackbarAutoHideDuration']}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ zIndex: 1200 }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.severity}
          sx={{ width: '100%' }}
          elevation={6}
          variant='filled'
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}
