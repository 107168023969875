import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import green from '@mui/material/colors/green'
import red from '@mui/material/colors/red'
import grey from '@mui/material/colors/grey'
import { useAppSharedState } from 'state/AppSharedContext'

import CONFIG from 'config.json'

const fdrBgColorMapping = {
  1: green[900],
  2: green[500],
  3: grey[600],
  4: red[600],
  5: red[900]
}
const fdrTextColorMapping = {
  1: 'white',
  2: 'black',
  3: 'black',
  4: 'white',
  5: 'white'
}

export default function Fixtures ({ fixtures, size }) {
  const { gameweekInfo } = useAppSharedState()
  const nextGameweek = gameweekInfo.next_gw.id
  const playerFixtures = fixtures?.filter(
    fixture =>
      fixture.gameweek_num >= nextGameweek &&
      fixture.gameweek_num < nextGameweek + 3
  )
  var playerFixturesByGameweekMap = {}
  if (playerFixtures === undefined) {
    playerFixturesByGameweekMap = {}
  } else {
    playerFixturesByGameweekMap = playerFixtures.reduce((acc, fixture) => {
      if (acc[fixture.gameweek_num]) {
        acc[fixture.gameweek_num].push(fixture)
      } else {
        acc[fixture.gameweek_num] = [fixture]
      }
      return acc
    }, {})
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0.1em 0.1em',
        padding: { xs: '0.05em', sm: '0.1em', md: '0.2em' },
        width: '100%',
        height: { xs: '42px', sm: '68px', md: '68px' },
        alignContent: 'center',
        alignItems: 'center',
        backgroundColor: grey[900],
        borderRadius: '0.2em',
        gap: { xs: '0.05em', sm: '0.1em', md: '0.2em' }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          // border: '1px solid black',
          // borderRadius: '0.4em',
          // backgroundColor: grey[900],
          // margin: '0.05em 0.05em',
          width: '100%',
          height: '100%',
          // height: { xs: '24px', sm: '32px', md: '50px' },
          // flexWrap: 'wrap',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: { xs: '0.05em', sm: '0.1em', md: '0.2em' }
        }}
      >
        {Object.values(playerFixturesByGameweekMap).length > 0 &&
          Object.values(playerFixturesByGameweekMap).map(fixtures => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                gap: '0.05em',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center'
              }}
            >
              {fixtures.map(fixture => (
                <Box
                  sx={{
                    display: 'flex',
                    height: '100%',
                    backgroundColor: fdrBgColorMapping[fixture.fdr],
                    width: '100%',
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                    borderRadius: '0.2em'
                  }}
                >
                  <Typography
                    align='center'
                    sx={{
                      fontSize: {
                        xs:
                          size === 'small'
                            ? '5.5px'
                            : size === 'medium'
                            ? '7px'
                            : '8px',
                        sm:
                          size === 'small'
                            ? '10px'
                            : size === 'medium'
                            ? '10px'
                            : '10px',
                        md:
                          fixtures.length === 2 && size === 'small'
                            ? '9px'
                            : fixtures.length === 2
                            ? '9px'
                            : size === 'small'
                            ? '10px'
                            : size === 'medium'
                            ? '12px'
                            : '12px'
                      },
                      fontWeight: '500',
                      // color: grey[500],
                      color: fdrTextColorMapping[fixture.fdr],
                      // border: '1px solid grey',
                      alignContent: 'center',
                      padding: {
                        xs: '0.01em',
                        sm: '0.4em',
                        md: '0.4em'
                      }
                      // paddingLeft: '0.2em',
                      // paddingRight: '0.2em',
                      // margin: {
                      //   xs: '0.05em',
                      //   sm: '0.05em',
                      //   md: '0.05em'
                      // }
                    }}
                  >
                    {fixture.team}
                    {'-' + fixture.home_away}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          // border: '1px solid black',
          // borderRadius: '0.2em',
          // backgroundColor: grey[900],
          width: '100%',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '0.2em'
        }}
      >
        {Object.values(playerFixturesByGameweekMap).length > 0 &&
          Object.values(playerFixturesByGameweekMap).map(fixtures => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                // height: '100%',
                width: '100%',
                gap: '0.05em',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  // height: '100%',
                  width: '100%',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                  borderRadius: '0.4em'
                }}
              >
                <Typography
                  align='center'
                  sx={{
                    fontSize: {
                      xs: '10px',
                      sm: '12px',
                      md: '12px'
                    },
                    fontWeight: 'bold',
                    color:
                      (fixtures
                        .map(fixture => fixture.xP)
                        .reduce((a, b) => a + b) <= CONFIG.range.form.poor &&
                        red[500]) ||
                      (fixtures
                        .map(fixture => fixture.xP)
                        .reduce((a, b) => a + b) > CONFIG.range.form.poor &&
                        fixtures
                          .map(fixture => fixture.xP)
                          .reduce((a, b) => a + b) <= CONFIG.range.form.avg &&
                        grey[100]) ||
                      (fixtures
                        .map(fixture => fixture.xP)
                        .reduce((a, b) => a + b) > CONFIG.range.form.avg &&
                        green[400]), // border: '1px solid grey',
                    alignContent: 'center'
                    // padding: {
                    //   xs: '0.01em',
                    //   sm: '0.4em',
                    //   md: '0.4em'
                    // }
                    // paddingLeft: '0.2em',
                    // paddingRight: '0.2em',
                    // margin: {
                    //   xs: '0.05em',
                    //   sm: '0.05em',
                    //   md: '0.05em'
                    // }
                  }}
                >
                  {fixtures.map(fixture => fixture.xP).reduce((a, b) => a + b)}
                </Typography>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  )
}
