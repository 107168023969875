import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import green from '@mui/material/colors/green'
import red from '@mui/material/colors/red'
import yellow from '@mui/material/colors/yellow'
import grey from '@mui/material/colors/grey'
import CONFIG from 'config.json'

export default function Form ({ form, size }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        // border: '1px solid black',
        borderRadius: '0.4em',
        backgroundColor: grey[900],
        margin: '0.05em 0.05em',
        width: '100%',
        height: { xs: '18px', sm: '22px', md: '24px' },
        flexWrap: 'wrap',
        justifyContent: 'space-between'
      }}
    >
      {form.map(form => (
        <Typography
          align='center'
          variant='caption'
          sx={{
            fontSize: {
              xs: size === 'small' ? '6.5px' : '8px',
              sm: '12px',
              md: '12px'
            },
            fontWeight: 'bold',
            // backgroundColor: grey[800],
            color:
              (form <= CONFIG.range.form.poor && red[500]) ||
              (form > CONFIG.range.form.poor &&
                form <= CONFIG.range.form.avg &&
                grey[400]) ||
              (form > CONFIG.range.form.avg && green[400]),
            alignContent: 'center',

            padding: size === 'small' ? '0em' : '0.2em',
            // borderLeft: '1px solid grey',
            // borderRight: '1px solid grey',
            // borderRadius: '0.4em',
            width: {
              xs: '1.4em',
              sm: '2em',
              md: '2em'
            }
            // margin: {
            //   xs: '0.01em',
            //   sm: '0.01em',
            //   md: '0em'
            // }
          }}
        >
          {form}
        </Typography>
      ))}
    </Box>
  )
}
