import Card from '@mui/material/Card'
import { Typography } from '@mui/material'
import grey from '@mui/material/colors/grey'
import Box from '@mui/material/Box'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import CONFIG from 'config.json'
import { useEffect } from 'react'
import {
  calculateNewInBankAfterRetainingPlayer,
  calculateNewInBankAfterSellingPlayer
} from 'utils/draft'

export default function TeamBasicInfoCard () {
  const {
    selectedDraft,
    transferMode,
    positionIdToTransfer,
    setPositionIdToTransfer,
    setSelectedDraft
  } = useDraftsSharedState()
  const managerSummaryColumns = CONFIG['managerSummaryColumns']

  // useEffect(() => {
  //   if (transferMode === false) {
  //     if (positionIdToTransfer !== null) {
  //       setSelectedDraft(prevDraftTeam => {
  //         const newDraftTeam = { ...prevDraftTeam }
  //         const playerToBeTransferred = newDraftTeam.players.find(
  //           player => player.position_id === positionIdToTransfer
  //         )
  //         newDraftTeam.in_bank = calculateNewInBankAfterRetainingPlayer(
  //           newDraftTeam.in_bank,
  //           playerToBeTransferred.stats.selling_price
  //         )

  //         return newDraftTeam
  //       })

  //       setPositionIdToTransfer(null)
  //     }
  //   } else {
  //     setSelectedDraft(prevDraftTeam => {
  //       const newDraftTeam = { ...prevDraftTeam }
  //       const playerToBeTransferred = newDraftTeam.players.find(
  //         player => player.position_id === positionIdToTransfer
  //       )
  //       newDraftTeam.in_bank = calculateNewInBankAfterSellingPlayer(
  //         newDraftTeam.in_bank,
  //         playerToBeTransferred.stats.selling_price
  //       )

  //       return newDraftTeam
  //     })
  //   }
  // }, [transferMode, positionIdToTransfer])

  return (
    <Card
      variant='outlined'
      className='TeamSidePanel__TeamStats'
      sx={{
        backgroundColor: grey[900],
        mt: theme => theme.spacing(0.5),
        mb: theme => theme.spacing(0.5)
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          justifyItems: 'center',
          // paddingLeft: theme => theme.spacing(1),
          flexWrap: 'wrap'
          // paddingRight: theme => theme.spacing(1)
          // paddingTop: theme => theme.spacing(1)
        }}
      >
        {managerSummaryColumns.map((column, index) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: theme => theme.spacing(1),
              justifyContent: 'center',
              textAlign: 'center',
              justifyItems: 'center',
              alignContent: 'center',
              alignItems: 'center',
              padding: { xs: '0px 2px', sm: '0px 4px', md: '0px 4px' }
            }}
          >
            <Typography
              variant='body2'
              sx={{
                // mr: theme => theme.spacing(1),
                fontWeight: '600',
                fontSize: { xs: '12px', sm: '14px', md: '16px' },
                textWrap: 'nowrap'
              }}
            >
              {column.label}
            </Typography>
            <Typography
              variant='body2'
              sx={{ fontSize: { xs: '12px', sm: '14px', md: '16px' } }}
            >
              {selectedDraft[column.field] !== undefined
                ? selectedDraft[column.field]
                : column.default}
              {selectedDraft[column.field] !== undefined ? column.unit : ''}
            </Typography>
          </Box>
        ))}
      </Box>
    </Card>
  )
}
