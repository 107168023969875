import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import EmailIcon from '@mui/icons-material/Email'
import TwitterIcon from '@mui/icons-material/Twitter'
import { Link } from 'react-scroll'
import Brand from 'components/top_nav_bar/Brand'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'utils/globalSettings'

export default function TopNavBar () {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openDialog, setOpenDialog] = useState(false) // State for the dialog
  const navigate = useNavigate()

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleGuestLogin = event => {
    event.preventDefault()
    const requestData = {
      is_guest: true
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/${ROUTES.login}`, requestData)
      .then(res => {
        localStorage.setItem('auth', JSON.stringify(res.data))
        navigate('/')
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleDialogOpen = () => {
    setOpenDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  return (
    <>
      <AppBar position='sticky' color='primary' sx={{ bgcolor: '#1e1e2f' }}>
        <Toolbar>
          <Typography
            variant='h6'
            component='div'
            sx={{
              flexGrow: 1,
              fontWeight: 'bold',
              cursor: 'pointer',
              color: 'white'
            }}
          >
            <Brand />
          </Typography>

          {/* Desktop Links */}
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 2,
              alignItems: 'center'
            }}
          >
            <Link
              to='features'
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              <Button color='inherit'>Features</Button>
            </Link>
            <Link to='faq' spy={true} smooth={true} offset={50} duration={500}>
              <Button color='inherit'>FAQ</Button>
            </Link>
            <Button
              href='https://twitter.com/FPLGarage' // Replace with your actual Twitter link
              target='_blank'
              rel='noopener noreferrer'
              startIcon={<TwitterIcon />}
              sx={{
                textTransform: 'none',
                color: 'inherit',
                fontWeight: 'bold'
              }}
            >
              Twitter
            </Button>
            <Button
              onClick={handleDialogOpen} // Open dialog on click
              startIcon={<EmailIcon />}
              sx={{
                textTransform: 'none',
                color: 'inherit',
                fontWeight: 'bold'
              }}
            >
              Contact Us
            </Button>
            <Button
              variant='contained'
              color='secondary'
              sx={{ textTransform: 'none', fontWeight: 'bold' }}
              onClick={handleGuestLogin}
            >
              Try FPL Garage Now
            </Button>
          </Box>

          {/* Mobile Menu */}
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose}>
                <Link
                  to='features'
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  Features
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link
                  to='faq'
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  FAQ
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Button
                  href='https://twitter.com/FPLGarage'
                  target='_blank'
                  rel='noopener noreferrer'
                  startIcon={<TwitterIcon />}
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    color: 'inherit'
                  }}
                >
                  Twitter
                </Button>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleMenuClose()
                  handleDialogOpen()
                }}
              >
                <Button
                  startIcon={<EmailIcon />}
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    color: 'inherit'
                  }}
                >
                  Contact Us
                </Button>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Button
                  variant='contained'
                  color='secondary'
                  sx={{ textTransform: 'none', fontWeight: 'bold' }}
                  onClick={handleGuestLogin}
                >
                  Try FPL Garage Now
                </Button>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Contact Us Dialog */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Contact Us</DialogTitle>
        <DialogContent>
          <Typography variant='body1' gutterBottom>
            We'd love to hear from you! Feel free to reach out to us for:
          </Typography>
          <ul>
            <li>Feature ideas</li>
            <li>Feedback</li>
            <li>Website Issues</li>
          </ul>
          <Typography variant='body1' mt={2}>
            Email: <a href='mailto:fplomen7@gmail.com'>fplomen7@gmail.com</a>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
