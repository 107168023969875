import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function LoginSuccess () {
  const navigate = useNavigate()

  useEffect(() => {
    // Extract tokens from URL parameters
    const urlParams = new URLSearchParams(window.location.search)
    const access = urlParams.get('access')
    const refresh = urlParams.get('refresh')
    const email = urlParams.get('email')
    const id = urlParams.get('id')

    if (access && refresh && email && id) {
      // Save tokens to local storage or state management
      localStorage.setItem(
        'auth',
        JSON.stringify({
          tokens: { access, refresh },
          user: { email, id, is_verified: true }
        })
      )

      // Redirect to home or another route
      navigate('/')
    }
  }, [navigate])

  return <div>Logging in...</div>
}
