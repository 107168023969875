import './Drafts.css'
import Box from '@mui/material/Box'
import TeamFieldPanel from 'components/team_field_panel/TeamFieldPanel'
import PlayersTeamsPanel from 'components/players_teams_panel/PlayersTeamsPanel'
import { DraftsSharedStateProvider } from '../../state/DraftsSharedContext' // Updated import
import SetTeamIdButtonDialog from 'components/set_team_id_button/SetTeamIdButtonDialog'
import { useAppSharedState } from 'state/AppSharedContext'
import { useEffect, useRef, useState } from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import CONFIG from 'config.json'

export default function Drafts () {
  const fieldRef = useRef(null)
  const tableRef = useRef(null)

  const loaderMessages = CONFIG.loaderMessages

  // State to hold the selected message
  const [randomMessage, setRandomMessage] = useState('')

  useEffect(() => {
    const handleResize = () => {
      const fieldHeight = 1050 // Desired height of the field
      const tableHeight = 600

      // Get the current window width
      const windowWidth = window.innerWidth

      // Check if the screen size is md or larger
      const isMdOrLarger = windowWidth >= 1080

      if (isMdOrLarger) {
        // Scale TeamFieldPanel
        if (fieldRef.current) {
          const scaleFactor = window.innerHeight / fieldHeight
          fieldRef.current.style.transform = `scale(1,${Math.min(
            scaleFactor,
            1
          )})`
          fieldRef.current.style.transformOrigin = 'top' // Keep the top position intact
        }

        // Scale PlayersTeamsPanel
        if (tableRef.current) {
          const scaleFactor = window.innerHeight / tableHeight
          tableRef.current.style.transform = `scale(1,${Math.min(
            scaleFactor,
            1
          )})`
          tableRef.current.style.transformOrigin = 'left top' // Keep the top position intact
        }
      } else {
        // Reset scaling for smaller screens
        if (fieldRef.current) {
          fieldRef.current.style.transform = 'none'
        }
        if (tableRef.current) {
          tableRef.current.style.transform = 'none'
        }
      }
    }

    window.addEventListener('resize', handleResize)

    // Initial adjustment
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const { fplTeamId, setTeamIdDialogOpen, draftsPageLoader } =
    useAppSharedState()

  useEffect(() => {
    if (fplTeamId === null) {
      setTeamIdDialogOpen(true)
    } else {
      setTeamIdDialogOpen(false)
    }
  }, [fplTeamId, setTeamIdDialogOpen])

  useEffect(() => {
    if (draftsPageLoader) {
      const randomIndex = Math.floor(Math.random() * loaderMessages.length)
      setRandomMessage(loaderMessages[randomIndex])
    }
  }, [draftsPageLoader])

  return (
    <DraftsSharedStateProvider>
      {/* Backdrop Loader */}
      <Backdrop
        open={draftsPageLoader}
        sx={{
          color: '#fff',
          zIndex: theme => theme.zIndex.drawer + 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',

          gap: 2
        }}
      >
        <CircularProgress color='inherit' />
        <Typography
          variant='h6'
          sx={{
            marginTop: 2,
            color: '#fff',
            textAlign: 'center',
            fontSize: { xs: '16px', sm: '20px', md: '24px' },
            width: { xs: '80%', sm: '80%', md: 'auto' }
          }}
        >
          {randomMessage}
        </Typography>
      </Backdrop>

      <Box
        className='Drafts'
        sx={{
          display: 'flex',
          flexDirection: { xs: 'row', sm: 'row', md: 'row' }, // Stack on smaller screens
          width: '100%',
          height: '100%', // Full viewport height
          justifyContent: { xs: 'center', sm: 'center', md: 'center' },
          alignItems: 'flex-start',
          // overflow: 'auto',
          margin: {
            xs: '0px 0px 0px 0px',
            sm: '0 0',
            md: '30px 24px 8px 24px'
          },
          gap: { xs: '0px', sm: '0px', md: '24px' }
        }}
      >
        <Box
          ref={fieldRef}
          sx={{
            position: { xs: 'static', sm: 'static', md: 'sticky' },
            top: { xs: '0', sm: '0', md: '12px' }, // Fixed position on larger screens
            margin: '12px 0px 0px 0px',
            display: { xs: 'none', sm: 'none', md: 'flex' }
          }}
        >
          <TeamFieldPanel />
        </Box>

        <Box
          ref={tableRef}
          sx={{
            display: { xs: 'flex', sm: 'flex', md: 'flex' },
            overflow: 'auto', // Scrollable if content overflows,
            padding: { xs: '0 0px', sm: '0 0px', md: '0 0px' },
            width: '100%'
          }}
        >
          <PlayersTeamsPanel />
        </Box>

        {/* Set Team ID Dialog */}
        {/* {fplTeamId === null && <SetTeamIdButtonDialog />} */}
      </Box>
    </DraftsSharedStateProvider>
  )
}
