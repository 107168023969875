// DraftsSharedContext.js
import { createContext, useContext, useEffect, useState } from 'react'
import CONFIG from 'config.json'
import { ROUTES } from 'utils/globalSettings'
import axiosService from 'utils/axios'

const SharedStateContext = createContext()

export const AppSharedStateProvider = ({ children }) => {
  const [gameweek, setGameweek] = useState(CONFIG['gameweek'])
  const [fplTeamId, setFplTeamId] = useState(null)
  const [teamInfo, setTeamInfo] = useState({})
  const [gameweekInfo, setGameweekInfo] = useState({})
  const [managerSummary, setManagerSummary] = useState({})
  const [teamIdDialogOpen, setTeamIdDialogOpen] = useState(false)
  const [userLoggedIn, setUserLoggedIn] = useState(false)
  const [draftsPageLoader, setDraftsPageLoader] = useState(true)

  useEffect(() => {
    if (fplTeamId !== null) {
      return
    }
    const auth = JSON.parse(localStorage.getItem('auth'))
    const isGuest = auth.user.is_guest

    var isAuthenticated = false

    if (isGuest) {
      isAuthenticated = auth && auth.user
    } else {
      isAuthenticated =
        auth && auth.user && auth.user.is_verified && auth.user.email
    }

    setUserLoggedIn(isAuthenticated)

    if (isAuthenticated) {
      axiosService
        .get(ROUTES.userDetail)
        .then(response => {
          setFplTeamId(response.data.team_id)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }, [])

  //  get team info when team id changes
  useEffect(() => {
    if (!fplTeamId) {
      return
    }
    axiosService
      .get(ROUTES.getTeamInfo)
      .then(response => {
        setTeamInfo(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [fplTeamId])

  //  get gameweek info when gameweek changes
  useEffect(() => {
    if (!fplTeamId) {
      return
    }
    axiosService
      .get(ROUTES.getGameweekInfo)
      .then(response => {
        setGameweekInfo(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [fplTeamId])

  return (
    <SharedStateContext.Provider
      value={{
        gameweek,
        setGameweek,
        fplTeamId,
        setFplTeamId,
        managerSummary,
        setManagerSummary,
        teamInfo,
        setTeamInfo,
        gameweekInfo,
        setGameweekInfo,
        teamIdDialogOpen,
        setTeamIdDialogOpen,
        userLoggedIn,
        draftsPageLoader,
        setDraftsPageLoader
      }}
    >
      {children}
    </SharedStateContext.Provider>
  )
}

export const useAppSharedState = () => useContext(SharedStateContext)
