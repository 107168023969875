import React from 'react'
import TopNavBar from './TopNavBar' // Navigation Bar Component
import Features from './Features' // Dummy Features Component
import FAQ from './FAQ' // Dummy FAQ Component
import MainHeadline from './MainHeadline'
import PersonalStoryHeadline from './PersonalStoryHeadline'
import { Box } from '@mui/material'

export default function Login () {
  return (
    <div>
      {/* Top Navigation Bar */}
      <TopNavBar />

      {/* Main Headline */}
      <MainHeadline />

      {/* Personal Story Headline */}
      <PersonalStoryHeadline />

      {/* Features Section */}
      <Features />

      {/* FAQ Section */}
      <FAQ />

      {/* Footer (Placeholder) */}
      <footer
        style={{
          textAlign: 'center',
          padding: '16px',
          backgroundColor: '#1e1e2f',
          color: 'white'
        }}
      >
        <p>Copyright © FPL Garage {new Date().getFullYear()}</p>
      </footer>
    </div>
  )
}
