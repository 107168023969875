import * as React from 'react'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import grey from '@mui/material/colors/grey'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import SettingsIcon from '@mui/icons-material/Settings'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import SetTeamIdButtonDialog from 'components/set_team_id_button/SetTeamIdButtonDialog'
import axiosService from 'utils/axios'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'utils/globalSettings'
import { useAppSharedState } from 'state/AppSharedContext'
import Chip from '@mui/material/Chip'
import Brand from './Brand'
import TwitterIcon from '@mui/icons-material/Twitter'
import EmailIcon from '@mui/icons-material/Email'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

export default function TopNavBar (props) {
  const { teamInfo, setTeamIdDialogOpen, fplTeamId } = useAppSharedState()
  const navigate = useNavigate()
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const [openDialog, setOpenDialog] = React.useState(false) // State for the dialog

  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogout = () => {
    const auth = JSON.parse(localStorage.getItem('auth'))
    const refreshToken = auth.tokens.refresh
    axiosService
      .post(ROUTES.logout, { refresh_token: refreshToken })
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        handleCloseUserMenu()
        localStorage.removeItem('auth')
        navigate('/login/')
      })
  }

  const handleSetTeamId = () => {
    setTeamIdDialogOpen(true)
    handleCloseUserMenu()
  }

  const handleDialogOpen = () => {
    setOpenDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  const settings = [
    {
      key: 'set_team_id',
      label: 'Set Team ID',
      on_click: handleSetTeamId
    },
    {
      key: 'contact_us',
      label: 'Contact Us',
      on_click: () => {
        handleCloseUserMenu()
        handleDialogOpen()
      }
    },
    {
      key: 'twitter',
      label: 'Twitter',
      on_click: () => {
        handleCloseUserMenu()
        window.open('https://twitter.com/FPLGarage', '_blank')
      }
    },
    {
      key: 'logout',
      label: 'Logout',
      on_click: handleLogout
    }
  ]

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: grey[800],
          height: { xs: '60px', sm: '60px', md: '60px' }
        }}
      >
        <AppBar
          sx={{
            position: 'fixed',
            top: 0
          }}
        >
          <Toolbar>
            <Brand />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                flexGrow: 1,
                alignContent: 'center',
                alignItems: 'center',
                height: { xs: '60px', sm: '60px', md: '60px' }
              }}
            >
              {fplTeamId && (
                <Chip
                  label={'Team ID : ' + fplTeamId}
                  sx={{
                    ml: '0.5em',
                    mr: '0.5em',
                    alignContent: 'center',
                    display: { xs: 'block', sm: 'block' }
                  }}
                />
              )}
              {teamInfo.summary && (
                <Chip
                  label={'Team : ' + teamInfo.summary.team_name}
                  sx={{
                    ml: '0.5em',
                    mr: '0.5em',
                    alignContent: 'center',
                    display: { xs: 'none', sm: 'block' }
                  }}
                />
              )}
              {teamInfo.summary && teamInfo.summary.rank_short_form && (
                <Chip
                  label={'Rank : ' + teamInfo.summary.rank_short_form}
                  sx={{
                    ml: '0.5em',
                    mr: '0.5em',
                    alignContent: 'center',
                    display: { xs: 'none', sm: 'none', md: 'block' }
                  }}
                />
              )}
              {/* {teamInfo.summary && (
              <Chip
                label={'Points : ' + teamInfo.summary.total_points}
                sx={{ ml: '0.5em', mr: '0.5em' }}
              />
            )} */}
              {teamInfo.summary && (
                <Chip
                  label={'Top ' + teamInfo.summary.top_x_percent + '%'}
                  sx={{
                    ml: '0.5em',
                    mr: '0.5em',
                    alignContent: 'center',
                    display: { xs: 'none', sm: 'none', md: 'block' }
                  }}
                />
              )}
              {teamInfo.summary && (
                <Chip
                  label={
                    'Avg Team Ownership : ' + teamInfo.summary.avg_own + '%'
                  }
                  sx={{
                    ml: '0.5em',
                    mr: '0.5em',
                    alignContent: 'center',
                    display: { xs: 'none', sm: 'none', md: 'block' }
                  }}
                />
              )}
            </Box>

            {/* Add Twitter and Contact Us for md and larger */}
            <Box
              sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, gap: 2 }}
            >
              <Button
                href='https://twitter.com/FPLGarage'
                target='_blank'
                rel='noopener noreferrer'
                startIcon={<TwitterIcon />}
                sx={{
                  textTransform: 'none',
                  color: 'inherit',
                  fontWeight: 'bold'
                }}
              >
                Twitter
              </Button>
              <Button
                onClick={handleDialogOpen}
                startIcon={<EmailIcon />}
                sx={{
                  textTransform: 'none',
                  color: 'inherit',
                  fontWeight: 'bold'
                }}
              >
                Contact Us
              </Button>
            </Box>

            <SetTeamIdButtonDialog />
            <Box sx={{}}>
              <Tooltip title='Open settings'>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map(setting => (
                  <MenuItem key={setting.key} onClick={setting.on_click}>
                    <Typography textAlign='center'>{setting.label}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>

      {/* Contact Us Dialog */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Contact Us</DialogTitle>
        <DialogContent>
          <Typography variant='body1' gutterBottom>
            We'd love to hear from you! Feel free to reach out to us for:
          </Typography>
          <ul>
            <li>Feature ideas</li>
            <li>Feedback</li>
            <li>Website issues</li>
          </ul>
          <Typography variant='body1' fontWeight='bold' mt={2}>
            Email: <a href='mailto:fplomen7@gmail.com'>fplomen7@gmail.com</a>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
