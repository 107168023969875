import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CloseIcon from '@mui/icons-material/Close'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import {
  getPlayers,
  calculateNewTeamAvgOwnershipAfterRemovingPlayer,
  calculateNewInBankAfterRemovingPlayer,
  calculateNewTeamValueAfterRemovingPlayer,
  findPositionWithMinimumPlayersInTheMainTeam,
  calculateNewInBankAfterSellingPlayer,
  calculateNewInBankAfterRetainingPlayer
} from 'utils/draft'
import CONFIG from 'config.json'
import { useState } from 'react'
import Tooltip from '@mui/material/Tooltip'
import axiosService from 'utils/axios'
import { ROUTES } from 'utils/globalSettings'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const optionsForMainTeam = [
  'Transfer',
  'Substitute',
  'Captain',
  'Vice-Captain',
  'Remove'
]
const optionsForSub = ['Transfer', 'Substitute', 'Remove']

const ITEM_HEIGHT = 48

export default function PlayerMenu ({ playerInfo }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: '',
    severity: ''
  })
  const {
    selectedDraft,
    setSelectedDraft,
    selectedDraftId,
    setHighlightedPlayerIds,
    playerSubstituteCandidateId,
    setPlayerSubstituteCandidateId,
    selectedDraftCaptainPositionId,
    selectedDraftViceCaptainPositionId,
    setSelectedDraftCaptainPositionId,
    setSelectedDraftViceCaptainPositionId,
    positionIdToTransfer,
    setPositionIdToTransfer,
    transferMode,
    setTransferMode,
    setTransferModeEmptyTeam,
    setCurrentRightPanelTab,
    draftBudget,
    setDraftBudget,
    setSaveDraftTrigger
  } = useDraftsSharedState()

  const theme = useTheme()

  const isXs = useMediaQuery(theme.breakpoints.only('xs'))
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))
  const open = Boolean(anchorEl)

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }

  const handleClick = event => {
    // event.stopPropagation() // Stop event propagation

    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleTransfer = () => {
    if (isXs || isSm) {
      setSnackbarMessage({
        message:
          'Loading players...Click on add player icon to complete transfer',
        severity: 'success'
      })
      setSnackbarOpen(true)
    } else {
      setSnackbarMessage({
        message: 'Click on add player icon from table to complete transfer',
        severity: 'success'
      })
      setSnackbarOpen(true)
    }

    setPositionIdToTransfer(playerInfo.position_id)
    const playerToBeTransferred = selectedDraft.players.find(
      player => player.position_id === playerInfo.position_id
    )
    setTransferMode(true)
    setTransferModeEmptyTeam(false)
    setHighlightedPlayerIds([])
    setPlayerSubstituteCandidateId(null)
    setDraftBudget(
      calculateNewInBankAfterSellingPlayer(
        selectedDraft.in_bank,
        playerToBeTransferred.stats.selling_price
      )
    )
    handleClose()

    // sleep for 5 seconds before switching to draft tab
    if (isXs || isSm) {
      setTimeout(() => {
        setCurrentRightPanelTab('players_rankings')
      }, 3000)
    }
  }

  const handleRemove = () => {
    setSelectedDraft(prevDraftTeam => {
      const newDraftTeam = { ...prevDraftTeam }

      // Calculating new avg ownership, in bank and team value
      const newAvgOwnership = calculateNewTeamAvgOwnershipAfterRemovingPlayer(
        newDraftTeam,
        playerInfo.stats.selected_by_percent
      )
      const newInBank = calculateNewInBankAfterRemovingPlayer(
        newDraftTeam.in_bank,
        playerInfo.stats.selling_price == null
          ? playerInfo.stats.now_cost
          : playerInfo.stats.selling_price
      )
      const newInValue = calculateNewTeamValueAfterRemovingPlayer(
        newDraftTeam.team_value,
        playerInfo.stats.now_cost
      )

      // Removing player
      const playerIndexToRemove = newDraftTeam.players.findIndex(
        player => player.id === playerInfo.id
      )
      newDraftTeam.players[playerIndexToRemove].id = null
      newDraftTeam.players[playerIndexToRemove].name = null
      newDraftTeam.players[playerIndexToRemove].team = null
      newDraftTeam.players[playerIndexToRemove].stats = {
        now_cost: null,
        ep_this: null,
        form: null,
        selected_by_percent: null,
        purchase_price: null,
        selling_price: null
      }

      // Update manager summary
      newDraftTeam.in_bank = newInBank
      setDraftBudget(newInBank)
      newDraftTeam.team_value = newInValue
      newDraftTeam.avg_own = newAvgOwnership

      return newDraftTeam
    })

    handleClose()
  }

  const handleCaptain = () => {
    var newViceCaptainPositionId
    if (selectedDraftViceCaptainPositionId === playerInfo.position_id) {
      setSelectedDraftViceCaptainPositionId(selectedDraftCaptainPositionId)
      newViceCaptainPositionId = selectedDraftCaptainPositionId
    } else {
      newViceCaptainPositionId = null
    }

    setSelectedDraftCaptainPositionId(playerInfo.position_id)
    setSelectedDraft(prevDraftTeam => {
      const newDraftTeam = { ...prevDraftTeam }

      // set is_captain of new captain player = true
      const newCaptainPlayerIndex = newDraftTeam.players.findIndex(
        player => player.position_id === playerInfo.position_id
      )
      newDraftTeam.players[newCaptainPlayerIndex].is_captain = true
      newDraftTeam.players[newCaptainPlayerIndex].is_vice_captain = false
      newDraftTeam.captain_position_id = playerInfo.position_id

      if (newViceCaptainPositionId !== null) {
        newDraftTeam.vice_captain_position_id = newViceCaptainPositionId
      }

      return newDraftTeam
    })

    setSaveDraftTrigger(true)

    handleClose()
  }

  const handleViceCaptain = () => {
    var newCaptainPositionId
    if (selectedDraftCaptainPositionId === playerInfo.position_id) {
      setSelectedDraftCaptainPositionId(selectedDraftViceCaptainPositionId)
      newCaptainPositionId = selectedDraftViceCaptainPositionId
    } else {
      newCaptainPositionId = null
    }
    setSelectedDraftViceCaptainPositionId(playerInfo.position_id)
    setSelectedDraft(prevDraftTeam => {
      const newDraftTeam = { ...prevDraftTeam }

      // set is_captain of new captain player = true
      const newViceCaptainPositionIndex = newDraftTeam.players.findIndex(
        player => player.position_id === playerInfo.position_id
      )
      newDraftTeam.players[newViceCaptainPositionIndex].is_vice_captain = true
      newDraftTeam.players[newViceCaptainPositionIndex].is_captain = false
      newDraftTeam.vice_captain_position_id = playerInfo.position_id

      if (newCaptainPositionId !== null) {
        newDraftTeam.captain_position_id = newCaptainPositionId
      }

      return newDraftTeam
    })
    setSaveDraftTrigger(true)
    handleClose()
  }

  const handleSubstituteFromMainTeam = () => {
    // find all players in draft team with inMainTeam=False and with same position
    setTransferMode(false)
    setTransferModeEmptyTeam(false)

    setPositionIdToTransfer(null)
    var substitutePlayers = []

    if (playerInfo.position === 'GKP') {
      substitutePlayers = getPlayers({
        draftTeam: selectedDraft,
        position: ['GKP'],
        inMainTeam: [false]
      })
    } else {
      const positionWithMinimumPlayersInTheMainTeam =
        findPositionWithMinimumPlayersInTheMainTeam(selectedDraft)

      if (positionWithMinimumPlayersInTheMainTeam === playerInfo.position) {
        substitutePlayers = getPlayers({
          draftTeam: selectedDraft,
          position: [playerInfo.position],
          inMainTeam: [false]
        })
      } else {
        substitutePlayers = getPlayers({
          draftTeam: selectedDraft,
          position: ['DEF', 'MID', 'FWD'],
          inMainTeam: [false]
        })
      }
    }

    // get player ids of substitute players in a list
    const substitutePlayersIds = []
    substitutePlayers.forEach(player => {
      substitutePlayersIds.push(player.position_id)
    })
    setHighlightedPlayerIds(substitutePlayersIds)

    setPlayerSubstituteCandidateId(playerInfo.position_id)

    handleClose()
  }

  const handleSubstituteFromBench = () => {
    setTransferMode(false)
    setTransferModeEmptyTeam(false)
    setPositionIdToTransfer(null)
    var substitutePlayers = []

    if (playerInfo.position === 'GKP') {
      substitutePlayers = getPlayers({
        draftTeam: selectedDraft,
        position: ['GKP'],
        excludePositionId: [playerInfo.position_id]
      })
    } else {
      const positionWithMinimumPlayersInTheMainTeam =
        findPositionWithMinimumPlayersInTheMainTeam(selectedDraft)

      if (
        positionWithMinimumPlayersInTheMainTeam &&
        playerInfo.position !== positionWithMinimumPlayersInTheMainTeam
      ) {
        substitutePlayers = getPlayers({
          draftTeam: selectedDraft,
          position: ['GKP', 'DEF', 'MID', 'FWD'].filter(
            item =>
              !['GKP', positionWithMinimumPlayersInTheMainTeam].includes(item)
          ),
          excludePositionId: [playerInfo.position_id]
        }).concat(
          getPlayers({
            draftTeam: selectedDraft,
            position: [positionWithMinimumPlayersInTheMainTeam],
            inMainTeam: [false],
            excludePositionId: [playerInfo.position_id]
          })
        )
      } else {
        substitutePlayers = getPlayers({
          draftTeam: selectedDraft,
          position: ['DEF', 'MID', 'FWD'],
          excludePositionId: [playerInfo.position_id]
        })
      }
    }

    // get player ids of substitute players in a list
    const substitutePlayersIds = []
    substitutePlayers.forEach(player => {
      substitutePlayersIds.push(player.position_id)
    })
    setHighlightedPlayerIds(substitutePlayersIds)

    setPlayerSubstituteCandidateId(playerInfo.position_id)
    handleClose()
  }

  const handleCancelTransferOrSubstitute = () => {
    if (transferMode) {
      const playerToBeTransferred = selectedDraft.players.find(
        player => player.position_id === positionIdToTransfer
      )
      setDraftBudget(
        (draftBudget - playerToBeTransferred.stats.selling_price).toFixed(1)
      )
    }
    setTransferMode(false)
    setTransferModeEmptyTeam(false)

    setPositionIdToTransfer(null)
    setHighlightedPlayerIds([])
    setPlayerSubstituteCandidateId(null)
  }

  return (
    <>
      {positionIdToTransfer === playerInfo.position_id ||
      playerSubstituteCandidateId === playerInfo.position_id ? (
        <Tooltip title='Cancel Transfer/Substitution' placement='right'>
          <IconButton
            aria-label='more'
            size='small'
            id='long-button'
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleCancelTransferOrSubstitute}
          >
            <CloseIcon sx={{ width: '0.9em', color: 'black' }} />
          </IconButton>
        </Tooltip>
      ) : (
        playerInfo.id && (
          <IconButton
            aria-label='more'
            size='medium'
            id='long-button'
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleClick}
          >
            <MoreVertIcon sx={{ color: 'black' }} />
          </IconButton>
        )
      )}
      {playerInfo.id && (
        <Menu
          id='long-menu'
          MenuListProps={{
            'aria-labelledby': 'long-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '14ch'
              }
            }
          }}
        >
          {playerInfo.in_main_team &&
            optionsForMainTeam.map(option => (
              <MenuItem
                key={option}
                selected={option === 'Pyxis'}
                onClick={() => {
                  if (option === 'Transfer') {
                    handleTransfer()
                  } else if (option === 'Remove') {
                    handleRemove()
                  } else if (option === 'Captain') {
                    handleCaptain()
                  } else if (option === 'Vice-Captain') {
                    handleViceCaptain()
                  } else if (option === 'Substitute') {
                    handleSubstituteFromMainTeam()
                  }
                  handleClose()
                }}
              >
                {option}
              </MenuItem>
            ))}

          {!playerInfo.in_main_team &&
            optionsForSub.map(option => (
              <MenuItem
                key={option}
                selected={option === 'Pyxis'}
                onClick={() => {
                  if (option === 'Transfer') {
                    handleTransfer()
                  } else if (option === 'Remove') {
                    handleRemove()
                  } else if (option === 'Substitute') {
                    handleSubstituteFromBench()
                  }

                  handleClose()
                }}
              >
                {option}
              </MenuItem>
            ))}
        </Menu>
      )}
      <Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={CONFIG['snackbarAutoHideDuration']}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{
            zIndex: 1200
            // mt: {
            //   xs: '12px',
            //   sm: '24px',
            //   md: `48px`
            // }
          }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarMessage.severity}
            sx={{ width: '100%' }}
            elevation={6}
            variant='filled'
          >
            {snackbarMessage.message}
          </Alert>
        </Snackbar>
      </Box>
    </>
  )
}
