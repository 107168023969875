import './PlayerStat.css'
import { Typography } from '@mui/material'
import grey from '@mui/material/colors/grey'
import red from '@mui/material/colors/red'
import green from '@mui/material/colors/green'
import CONFIG from 'config.json'

export default function PlayerStat ({ valueToDisplay, statKey }) {
  return (
    <>
      <Typography
        align='center'
        variant='caption'
        sx={{
          width: { xs: '100%', sm: '100%', md: '100%' },
          height: '100%',
          fontSize: { xs: '10px', sm: '14px', md: '14px' },
          fontWeight: 'bold',
          alignContent: 'center',
          backgroundColor:
            valueToDisplay !== undefined &&
            valueToDisplay !== null &&
            grey[900],
          color:
            (CONFIG.range[statKey] &&
              ((valueToDisplay <= CONFIG.range[statKey].poor && red[500]) ||
                (valueToDisplay > CONFIG.range[statKey].poor &&
                  valueToDisplay <= CONFIG.range[statKey].avg &&
                  grey[400]) ||
                (valueToDisplay > CONFIG.range[statKey].avg && green[400]))) ||
            'white',
          margin: '0.05em',
          borderRadius: '0.4em'
        }}
      >
        {valueToDisplay}
        {
          CONFIG['playerInfoRadioButtonOptions'].find(
            option => option.field === statKey
          )?.unit
        }
      </Typography>
    </>
  )
}
