import React from 'react'
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function FAQ () {
  const faqs = [
    {
      question: 'What is FPL Garage?',
      answer:
        'FPL Garage is a platform designed to help Fantasy Premier League managers make smarter, data-driven decisions with tools like Drafts Planner, Players Rankings, and AI Assistant.'
    },
    {
      question: 'What makes FPL Garage stand out in a crowded market of FPL tools?',
      answer:
        'FPL Garage stands out with its simple, clutter-free design and a winning mindset, focusing only on tools that directly improve your rank—no gimmicks like AI teams or price predictions. We’re a community-driven platform, listening to feedback, boldly innovating, and providing unique features.'
    },
    {
      question: 'Is FPL Garage free to use?',
      answer:
        'Yes, for this season FPL Garage will offer free access for all features. In future seasons, platform might charge a subscription fee.'
    },
    // {
    //   question: 'How does the AI Assistant work?',
    //   answer:
    //     'Our AI Assistant analyzes player stats, fixtures, and trends to provide customized team recommendations and insights for transfers, captaincy, and chips.'
    // },
    {
      question: 'Can I use FPL Garage without logging in?',
      answer:
        'Absolutely! You can explore most of the features as a guest user.'
    }
    // {
    //   question: 'What are the upcoming features?',
    //   answer:
    //     'We’re constantly innovating! Upcoming features include advanced Expected Points models, a personal assistant for FPL strategies, and insightful blogs to level up your FPL knowledge.'
    // }
  ]

  return (
    <Box
      id='faq'
      sx={{
        padding: { xs: 4, md: 8 },
        bgcolor: 'background.paper',
        textAlign: 'center'
      }}
    >
      {/* FAQ Title */}
      <Typography
        variant='h3'
        component='h3'
        sx={{
          fontWeight: 'bold',
          marginBottom: 4,
          color: 'primary.main'
        }}
      >
        Frequently Asked Questions
      </Typography>

      {/* FAQ Accordion */}
      <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
        {faqs.map((faq, index) => (
          <Accordion key={index} sx={{ marginBottom: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: { xs: '1rem', md: '1.4rem' }
                }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body1' sx={{ color: 'text.primary' }}>
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  )
}
