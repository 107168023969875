import './TeamFieldPanel.css'
import Box from '@mui/material/Box'
import grey from '@mui/material/colors/grey'
import { Typography } from '@mui/material'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import { useAppSharedState } from 'state/AppSharedContext'
import { useEffect, useState } from 'react'
import Keeper from './Keeper'
import Defenders from './Defenders'
import Midfielders from './Midfielders'
import Forwards from './Forwards'
import Subs from './Subs'
import FieldTopNavBar from './FieldTopNavBar'
import CONFIG from 'config.json'
import PlayerInfoSelectButton from './PlayerInfoSelectButton'
import Button from '@mui/material/Button'
import SyncDraftButton from './SyncDraftButton'
import axiosService from 'utils/axios'
import { ROUTES } from 'utils/globalSettings'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

export default function TeamFieldPanel () {
  const { fplTeamId, setTeamIdDialogOpen } = useAppSharedState()
  const {
    draftsList,
    setDraftsList,
    saveDraftTrigger,
    selectedDraft,
    selectedDraftId,
    setSaveDraftTrigger
  } = useDraftsSharedState()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: '',
    severity: ''
  })

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }

  const getNewDraftsList = () => {
    axiosService.get(ROUTES.getDraftsList).then(response => {
      setDraftsList(response.data)
    })
  }

  useEffect(() => {
    if (saveDraftTrigger === false) return
    axiosService
      .put(ROUTES.saveDraft + selectedDraftId + '/', selectedDraft)
      .then(res => {
        setSnackbarMessage({
          message: 'Draft saved!',
          severity: 'success'
        })
        setSnackbarOpen(true)
        getNewDraftsList()
      })

    setSaveDraftTrigger(false)
  }, [saveDraftTrigger])

  const handleSetTeamId = () => {
    setTeamIdDialogOpen(true) // Open the dialog for setting the FPL Team ID
  }
  // const { setPlayerSubstituteCandidateId, setHighlightedPlayerIds } =
  //   useDraftsSharedState()

  // useEffect(() => {
  //   const handleClickOutside = event => {
  //     const highlightedBoxes = document.querySelectorAll('.PlayerBox.highlight')
  //     let clickedInside = false

  //     highlightedBoxes.forEach(box => {
  //       if (box.contains(event.target)) {
  //         clickedInside = true
  //       }
  //     })

  //     if (!clickedInside) {
  //       setPlayerSubstituteCandidateId(null)
  //       setHighlightedPlayerIds([])
  //     }
  //   }

  //   document.addEventListener('click', handleClickOutside)

  //   return () => {
  //     document.removeEventListener('click', handleClickOutside)
  //   }
  // }, [setPlayerSubstituteCandidateId, setHighlightedPlayerIds])

  return (
    <Box
      className='TeamFieldPanel'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // height: { xs: 'max-content', sm: 'max-content', md: 'max-content' },
        width: { xs: '94vw', sm: '860px', md: '750px' },
        justifyContent: 'center'
        // alignItems: 'center',
        // alignContent: 'center'
      }}
    >
      <FieldTopNavBar />
      <Box
        className='TeamFieldPanel__Team'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid grey',
          borderRadius: '0.5em'
          // justifyContent: 'center',
          // alignItems: 'center',
          // alignContent: 'center'
        }}
      >
        <Box
          className='TeamFieldPanel__MainTeam'
          alignContent='center'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/field.jpeg)`,
            backgroundSize: {
              xs: '100% 100%',
              sm: '100% 100%',
              md: '100% 100%'
            },
            backgroundPosition: '0em -0.5em',
            borderTopLeftRadius: '0.5em',
            borderTopRightRadius: '0.5em',
            height: { xs: '450px', sm: '580px', md: '650px' },
            position: 'relative',
            width: { xs: '94vw', sm: '860px', md: '750px' },
            justifyContent: 'space-evenly'
          }}
        >
          <PlayerInfoSelectButton />
          <SyncDraftButton />
          {/* {fplTeamId !== null && draftsList.length === 0 ? (
            <Typography
              variant='body1'
              align='center'
              sx={{
                fontSize: { xs: '14px', sm: '20px', md: '20px' },
                fontWeight: '800',
                position: 'absolute',
                top: '40%',
                width: '100%'
              }}
            >
              Create a draft to sync team and get started!
            </Typography>
          ) : null} */}
          {fplTeamId === null ? (
            <Typography
              variant='body1'
              align='center'
              sx={{
                fontWeight: '600'
              }}
            >
              <Button
                variant='contained'
                color='primary'
                onClick={handleSetTeamId} // Trigger the set team ID dialog
              >
                Set FPL Team ID
              </Button>
            </Typography>
          ) : null}

          <Keeper />
          <Defenders />
          <Midfielders />
          <Forwards />
        </Box>
        <Subs />
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={CONFIG['snackbarAutoHideDuration']}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.severity}
          sx={{ width: '100%', zIndex: 1200 }}
          elevation={6}
          variant='filled'
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}
