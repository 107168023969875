import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'utils/globalSettings'

export default function MainHeadline () {
  const navigate = useNavigate()

  const handleGuestLogin = event => {
    event.preventDefault()
    const requestData = {
      is_guest: true
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/${ROUTES.login}`, requestData)
      .then(res => {
        localStorage.setItem('auth', JSON.stringify(res.data))
        navigate('/')
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: { xs: 2, md: 4 },
        bgcolor: 'background.paper',
        minHeight: { xs: '60vh', md: '100vh' },
        marginTop: { xs: '50px', sm: '100px', md: '200px' }
      }}
    >
      {/* Main Headline */}
      <Typography
        variant='h1'
        component='h1'
        gutterBottom
        sx={{
          fontWeight: 'bold',
          fontSize: { xs: '2rem', md: '6rem' },
          color: 'primary.main',
          width: '80vw'
        }}
      >
        A Remarkable FPL Experience For Those Who Play To Win
      </Typography>

      {/* Subtitle */}
      <Typography
        variant='h6'
        component='p'
        gutterBottom
        sx={{
          fontSize: { xs: '1rem', md: '1.25rem' },
          color: 'text.primary',
          maxWidth: '600px'
        }}
      >
        Take your Fantasy Premier League game to the next level with
        accurate data, simple tools, and unique insights.
      </Typography>

      {/* Call-to-Action Button */}
      <Button
        variant='contained'
        color='secondary'
        size='large'
        onClick={handleGuestLogin}
        sx={{
          marginTop: 3,
          textTransform: 'none',
          fontWeight: 'bold',
          fontSize: { xs: '0.875rem', md: '1rem' },
          padding: { xs: '8px 16px', md: '12px 24px' }
        }}
      >
        Try FPL Garage Now
      </Button>

      <Box
        component='img'
        src='/images/fpl_garage_demo.jpeg' // Ensure the image is placed in the public folder with this name
        alt='Website Preview'
        sx={{
          width: '60vw',
          borderRadius: 2,
          boxShadow: 3,

          marginTop: { xs: '50px', sm: '100px', md: '150px' }
        }}
      />
    </Box>
  )
}
