import React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Link } from 'react-scroll'
import Brand from 'components/top_nav_bar/Brand'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'utils/globalSettings'

export default function TopNavBar () {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const navigate = useNavigate()

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleGuestLogin = event => {
    event.preventDefault()
    // setLoading(true)

    const requestData = {
      is_guest: true
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/${ROUTES.login}`, requestData)
      .then(res => {
        localStorage.setItem('auth', JSON.stringify(res.data))
        navigate('/')
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <AppBar position='sticky' color='primary' sx={{ bgcolor: '#1e1e2f' }}>
      <Toolbar>
        <Typography
          variant='h6'
          component='div'
          sx={{
            flexGrow: 1,
            fontWeight: 'bold',
            cursor: 'pointer',
            color: 'white'
          }}
        >
          <Brand />
        </Typography>

        {/* Desktop Links */}
        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
          <Link
            to='features'
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            <Button color='inherit'>Features</Button>
          </Link>
          <Link to='faq' spy={true} smooth={true} offset={50} duration={500}>
            <Button color='inherit'>FAQ</Button>
          </Link>
          <Button
            variant='contained'
            color='secondary'
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
            onClick={handleGuestLogin} // Replace with actual routing logic
          >
            Try FPL Garage Now
          </Button>
        </Box>

        {/* Mobile Menu */}
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='menu'
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose}>
              <Link
                to='features'
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                Features
              </Link>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <Link
                to='faq'
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                FAQ
              </Link>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <Button
                variant='contained'
                color='secondary'
                sx={{ textTransform: 'none', fontWeight: 'bold' }}
                onClick={handleGuestLogin} // Replace with actual routing logic
              >
                Try FPL Garage Now
              </Button>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
