import MaterialTable from '@material-table/core'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import axiosService from 'utils/axios'
import Box from '@mui/material/Box'
import grey from '@mui/material/colors/grey'
import { useEffect, useState } from 'react'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import staticTableColumns from './rankings_table.json'
import allTableColumns from './table.json'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { useRef } from 'react'
import CONFIG from 'config.json'
import { useAppSharedState } from 'state/AppSharedContext'
import PlayerCell from './PlayerCell'
import {
  checkIfPlayerExists,
  checkIfPositionAvailable,
  calculateNewInBankAfterAddingPlayer,
  calculateNewTeamValueAfterAddingPlayer,
  calculateNewTeamAvgOwnershipAfterAddingPlayer,
  calculateNewInBankAfterTransferringPlayer,
  calculateNewTeamValueAfterTransferringPlayer,
  calculateNewTeamAvgOwnershipAfterTransferringPlayer,
  getPlayers,
  calculateTransfersLeftTransfersCost,
  filterPlayers
} from 'utils/draft'
import { ROUTES } from 'utils/globalSettings'
import { IconButton } from '@mui/material'
import PlayerFixture from './PlayerFixture'

export default function PlayersTable () {
  const { fplTeamId, teamInfo, gameweekInfo } = useAppSharedState()
  const theme = useTheme()

  const {
    playersTableData,
    setPlayersTableData,
    priceSliderValue,
    setSelectedDraft,
    selectedDraft,
    selectedDraftId,
    positionIdToTransfer,
    setPositionIdToTransfer,
    transferMode,
    setTransferMode,
    transferModeEmptyTeam,
    setTransferModeEmptyTeam,
    teamsSelectedForPlayersTable,
    positionsSelectedForPlayersTable,
    playersTableApplyTrigger,
    setPlayersTableApplyTrigger,
    showPlayersWithHighXminsForPlayersTable,
    nonInjuredPlayersOnlyForPlayersTable,
    statCategoryForPlayersTable,
    formGameweeksForPlayersTable,
    xpGameweeksForPlayersTable,
    formStatForPlayersTable,
    xpStatForPlayersTable,
    ownershipSliderValue,
    playersRankingsTableStatsInfo,
    originalPlayersData,
    setSaveDraftTrigger,
    setDraftBudget,
    setCurrentRightPanelTab
  } = useDraftsSharedState()

  const isXs = useMediaQuery(theme.breakpoints.only('xs'))
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))
  const isMd = useMediaQuery(theme.breakpoints.only('md'))

  // Set dynamic font sizes
  const fontSize = isXs ? '10px' : isSm ? '12px' : isMd ? '14px' : '16px'
  const headerFontSize = isXs ? '10px' : isSm ? '14px' : isMd ? '16px' : '18px'

  const addPlayerAlertMessages = CONFIG['addPlayerAlertMessages']
  const stateRef = useRef()
  stateRef.fplTeamId = fplTeamId

  const [columns, setColumns] = useState(staticTableColumns)
  const [loading, setLoading] = useState(true)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: '',
    severity: ''
  })

  const processPlayersColumns = playersData => {
    var newColumns = JSON.parse(JSON.stringify(staticTableColumns)) // Deep clone
    const nextGameweek = gameweekInfo.next_gw.id
    newColumns[0].cellStyle = {
      backgroundColor: transferMode ? '#36454F' : 'black',
      position: 'sticky',
      left: 50,
      zIndex: 1,
      textWrap: 'nowrap'
    }
    newColumns[0].render = rowData => {
      return (
        <>
          <PlayerCell
            playerName={rowData.web_name}
            playerTeam={rowData.team_name}
            playerPosition={rowData.position}
            playerPrice={rowData.now_cost}
          />
        </>
      )
    }

    const statKey =
      statCategoryForPlayersTable === 'form'
        ? formStatForPlayersTable
        : xpStatForPlayersTable
    const statGameweeks =
      statCategoryForPlayersTable === 'form'
        ? formGameweeksForPlayersTable
        : xpGameweeksForPlayersTable

    if (statCategoryForPlayersTable === 'form') {
      const statColumnConfig = {
        title: playersRankingsTableStatsInfo?.find(stat => stat.key === statKey)
          .title[statGameweeks],
        field: playersRankingsTableStatsInfo?.find(stat => stat.key === statKey)
          .field[statGameweeks],
        tooltip: playersRankingsTableStatsInfo?.find(
          stat => stat.key === statKey
        ).tooltip,
        searchable: false,
        filtering: false,
        type: 'numeric',
        hidden: false,
        defaultSort: 'desc'
      }
      const statExtraColumnsConfig = []

      for (const statConfig of playersRankingsTableStatsInfo) {
        if (statConfig.category === 'form') {
          if (statConfig.key !== statKey) {
            statExtraColumnsConfig.push({
              title: statConfig.title[statGameweeks],
              field: statConfig.field[statGameweeks],
              tooltip: statConfig.tooltip,
              searchable: false,
              filtering: false,
              type: 'numeric',
              hidden: true,
              defaultSort: 'desc'
            })
          }
        }
      }

      newColumns = [
        newColumns[0],
        statColumnConfig,
        ...newColumns.slice(1),
        ...statExtraColumnsConfig
      ]
    }

    if (statCategoryForPlayersTable === 'expected_points') {
      var gameweeksForXpFixtures = []
      var sortingAllowed = false
      var combinedXpColumnConfig = null
      if (xpGameweeksForPlayersTable === 'next') {
        gameweeksForXpFixtures.push(nextGameweek)
        sortingAllowed = true
      } else if (xpGameweeksForPlayersTable == '3') {
        gameweeksForXpFixtures.push(
          nextGameweek,
          nextGameweek + 1,
          nextGameweek + 2
        )
        sortingAllowed = false
        combinedXpColumnConfig = {
          title: playersRankingsTableStatsInfo?.find(
            stat => stat.key === statKey
          ).title[statGameweeks],
          field: playersRankingsTableStatsInfo?.find(
            stat => stat.key === statKey
          ).field[statGameweeks],
          tooltip: playersRankingsTableStatsInfo?.find(
            stat => stat.key === statKey
          ).tooltip,
          searchable: false,
          filtering: false,
          type: 'numeric',
          hidden: false,
          defaultSort: 'desc'
        }
      } else if (xpGameweeksForPlayersTable == '5') {
        gameweeksForXpFixtures.push(
          nextGameweek,
          nextGameweek + 1,
          nextGameweek + 2,
          nextGameweek + 3,
          nextGameweek + 4
        )
        sortingAllowed = false
        combinedXpColumnConfig = {
          title: playersRankingsTableStatsInfo?.find(
            stat => stat.key === statKey
          ).title[statGameweeks],
          field: playersRankingsTableStatsInfo?.find(
            stat => stat.key === statKey
          ).field[statGameweeks],
          tooltip: playersRankingsTableStatsInfo?.find(
            stat => stat.key === statKey
          ).tooltip,
          searchable: false,
          filtering: false,
          type: 'numeric',
          hidden: false,
          defaultSort: 'desc'
        }
      }

      const xpFixturesColumns = gameweeksForXpFixtures.map(gw => ({
        title: `Gameweek ${gw}`,
        field: playersRankingsTableStatsInfo?.find(stat => stat.key === statKey)
          .field[statGameweeks],
        searchable: false,
        filtering: false,
        sorting: sortingAllowed,
        defaultSort: 'desc',
        cellStyle: { whiteSpace: 'nowrap' },
        render: rowData => {
          return (
            <>
              <PlayerFixture gameweek={gw} playerData={rowData} />
            </>
          )
        }
      }))

      const formStatColumns = []
      for (const statConfig of playersRankingsTableStatsInfo) {
        if (statConfig.category === 'form') {
          for (const numOfGameweeks of ['overall', '3', '5']) {
            formStatColumns.push({
              title: statConfig.title[numOfGameweeks],
              field: statConfig.field[numOfGameweeks],
              tooltip: statConfig.tooltip,
              searchable: false,
              filtering: false,
              type: 'numeric',
              hidden: true,
              hiddenByColumnsButton: false
            })
          }
        }
      }

      newColumns = [
        newColumns[0],
        ...xpFixturesColumns,
        ...newColumns.slice(1),
        ...formStatColumns
      ]

      if (combinedXpColumnConfig) {
        newColumns = [
          newColumns[0],
          ...newColumns.slice(1, xpFixturesColumns.length + 1),
          combinedXpColumnConfig,
          ...newColumns.slice(
            xpFixturesColumns.length + 1,
            xpFixturesColumns.length + formStatColumns.length + 1
          )
        ]
      }
    }

    setColumns(newColumns)
  }

  const getPlayersData = () => {
    const [minPrice, maxPrice] = priceSliderValue
    const [minOwnership, maxOwnership] = ownershipSliderValue

    if (minPrice === undefined && maxPrice === undefined) {
      return
    }

    if (minOwnership === undefined && maxOwnership === undefined) {
      return
    }

    if (minPrice === null || maxPrice === null) {
      return
    }

    if (minOwnership === null || maxOwnership === null) {
      return
    }

    // const commaSeparatedStringParser = str =>
    //   str ? str.split(',').map(item => item.trim()) : []

    const queryPayload = {
      min_price: minPrice,
      max_price: maxPrice,
      min_ownership: minOwnership,
      max_ownership: maxOwnership,
      teams: teamsSelectedForPlayersTable,
      positions: positionsSelectedForPlayersTable,
      stat_category: statCategoryForPlayersTable,
      form_stat: formStatForPlayersTable,
      xp_stat: xpStatForPlayersTable,
      form_gameweeks: formGameweeksForPlayersTable,
      xp_gameweeks: xpGameweeksForPlayersTable,
      hide_injured: nonInjuredPlayersOnlyForPlayersTable
    }
    const filteredPlayers = filterPlayers(originalPlayersData, queryPayload)
    setPlayersTableData(filteredPlayers)
    processPlayersColumns(playersTableData)
    setLoading(false)

    // axiosService
    //   .post(ROUTES.playersList, queryPayload)
    //   .then(response => {
    //     setPlayersTableData(response.data)
    //     processPlayersColumns(response.data)
    //     setLoading(false)
    //   })
    //   .catch(error => {
    //     console.error('Error fetching data:', error)
    //     setLoading(false)
    //   })
  }

  useEffect(() => {
    setLoading(true)
    getPlayersData()
    setPlayersTableApplyTrigger(false)
  }, [
    teamsSelectedForPlayersTable,
    positionsSelectedForPlayersTable,
    priceSliderValue,
    ownershipSliderValue,
    xpGameweeksForPlayersTable,
    xpStatForPlayersTable,
    formGameweeksForPlayersTable,
    formStatForPlayersTable,
    statCategoryForPlayersTable
  ])

  useEffect(() => {
    if (gameweekInfo.gw === undefined || priceSliderValue[0] === null) {
      return
    }

    getPlayersData()
  }, [priceSliderValue, gameweekInfo, originalPlayersData])

  const handleAddPlayerTransferMode = (event, rowData) => {
    setSelectedDraft(prevDraftTeam => {
      const newDraftTeam = { ...prevDraftTeam }
      const playerToBeTransferred = newDraftTeam.players.find(
        player => player.position_id === positionIdToTransfer
      )

      // Check if fpl team id is synced
      if (stateRef.fplTeamId === null) {
        setSnackbarMessage(addPlayerAlertMessages['noTeamId'])
        setSnackbarOpen(true)
        setTransferMode(false)
        setTransferModeEmptyTeam(false)
        setPositionIdToTransfer(null)
        return prevDraftTeam // Return previous state without changes
      }

      // Check if player id already exists in the team
      if (checkIfPlayerExists(newDraftTeam, rowData.player_id)) {
        setSnackbarMessage(addPlayerAlertMessages['playerExists'])
        setSnackbarOpen(true)
        setTransferMode(false)
        setTransferModeEmptyTeam(false)

        setPositionIdToTransfer(null)
        return prevDraftTeam // Return previous state without changes
      }

      if (rowData.position !== playerToBeTransferred.position) {
        setSnackbarMessage({
          message: 'Add player with position ' + playerToBeTransferred.position,
          severity: 'error'
        })
        setSnackbarOpen(true)
        setTransferMode(false)
        setTransferModeEmptyTeam(false)

        setPositionIdToTransfer(null)
        return prevDraftTeam
      }

      const newInBank = calculateNewInBankAfterTransferringPlayer(
        newDraftTeam.in_bank,
        playerToBeTransferred.stats.selling_price == null
          ? playerToBeTransferred.stats.now_cost
          : playerToBeTransferred.stats.selling_price,
        rowData.now_cost
      )

      setDraftBudget(newInBank)

      const newTeamValue = calculateNewTeamValueAfterTransferringPlayer(
        newDraftTeam.team_value,
        playerToBeTransferred.stats.now_cost,
        rowData.now_cost
      )

      const newTeamAvgOwnership =
        calculateNewTeamAvgOwnershipAfterTransferringPlayer(
          newDraftTeam,
          playerToBeTransferred.stats.selected_by_percent,
          rowData.selected_by_percent
        )

      // Check if enough money in bank
      // if (newInBank < 0) {
      //   setSnackbarMessage(addPlayerAlertMessages['noFunds'])
      //   setSnackbarOpen(true)
      //   setTransferMode(false)
      //   setTransferModeEmptyTeam(false)

      //   setPositionIdToTransfer(null)
      //   return prevDraftTeam
      // }

      // Check if 3 players already from same team
      const playersFromSameTeam = getPlayers({
        draftTeam: newDraftTeam,
        teamFilter: [rowData.team_name],
        onlyAdded: true
      })

      if (playersFromSameTeam.length >= 3) {
        setSnackbarMessage(addPlayerAlertMessages['maxPlayersFromSameTeam'])
        setSnackbarOpen(true)
        setTransferMode(false)
        setTransferModeEmptyTeam(false)

        setPositionIdToTransfer(null)
        return prevDraftTeam
      }
      const positionIndex = newDraftTeam.players.findIndex(
        player => player.position_id === positionIdToTransfer
      )

      // find player object from teaminfo.picks.players matching transferred player id
      const playerAlreadyInOriginalTeam = teamInfo.picks.players.find(
        player => player.id === rowData.player_id
      )

      if (!transferModeEmptyTeam && playerAlreadyInOriginalTeam) {
        newDraftTeam.players[positionIndex] = {
          id: playerAlreadyInOriginalTeam.id,
          name: playerAlreadyInOriginalTeam.name,
          team: playerAlreadyInOriginalTeam.team,
          position: playerAlreadyInOriginalTeam.position,
          stats: {
            now_cost: playerAlreadyInOriginalTeam.stats.now_cost,
            ep_this: playerAlreadyInOriginalTeam.stats.ep_this,
            form: playerAlreadyInOriginalTeam.stats.form,
            selected_by_percent:
              playerAlreadyInOriginalTeam.stats.selected_by_percent,
            purchase_price: playerAlreadyInOriginalTeam.stats.purchase_price,
            selling_price: playerAlreadyInOriginalTeam.stats.selling_price
          },
          in_main_team: newDraftTeam.players[positionIndex].in_main_team,
          is_captain: newDraftTeam.players[positionIndex].is_captain,
          is_vice_captain: newDraftTeam.players[positionIndex].is_vice_captain,
          position_id: newDraftTeam.players[positionIndex].position_id
        }
      } else {
        newDraftTeam.players[positionIndex] = {
          id: rowData.player_id,
          name: rowData.web_name,
          team: rowData.team_name,
          position: rowData.position,
          stats: {
            now_cost: rowData.now_cost,
            ep_this: rowData.ep_this,
            form: rowData.form,
            selected_by_percent: rowData.selected_by_percent,
            purchase_price: null,
            selling_price: null
          },
          in_main_team: newDraftTeam.players[positionIndex].in_main_team,
          is_captain: newDraftTeam.players[positionIndex].is_captain,
          is_vice_captain: newDraftTeam.players[positionIndex].is_vice_captain,
          position_id: newDraftTeam.players[positionIndex].position_id
        }
      }

      // Updating manager summary
      newDraftTeam.in_bank = newInBank
      newDraftTeam.team_value = newTeamValue
      newDraftTeam.avg_own = newTeamAvgOwnership

      const transfersLeftTransfersCost = calculateTransfersLeftTransfersCost(
        teamInfo,
        newDraftTeam
      )

      newDraftTeam.transfers_left = transfersLeftTransfersCost.transfersLeft
      newDraftTeam.transfers_cost = transfersLeftTransfersCost.transfersCost

      setSnackbarMessage(addPlayerAlertMessages['playerTransferred'])
      setSnackbarOpen(true)
      setTransferMode(false)
      setTransferModeEmptyTeam(false)
      setPositionIdToTransfer(null)

      return newDraftTeam
    })

    setSaveDraftTrigger(true)

    if (isSm || isXs) {
      setCurrentRightPanelTab('draft')
    }
  }

  const handleAddPlayer = (event, rowData) => {
    // const { name, team, position, stats } = rowData

    setSelectedDraft(prevDraftTeam => {
      const newDraftTeam = { ...prevDraftTeam }
      // const newManagerSummary = { ...stateRef.managerSummary }

      // Check if fpl team id is synced
      if (stateRef.fplTeamId === null) {
        setSnackbarMessage(addPlayerAlertMessages['noTeamId'])
        setSnackbarOpen(true)
        return prevDraftTeam // Return previous state without changes
      }

      // Check if player id already exists in the team
      if (checkIfPlayerExists(newDraftTeam, rowData.player_id)) {
        setSnackbarMessage(addPlayerAlertMessages['playerExists'])
        setSnackbarOpen(true)
        return prevDraftTeam // Return previous state without changes
      }

      // Check if position is available
      if (!checkIfPositionAvailable(newDraftTeam, rowData.position)) {
        setSnackbarMessage(addPlayerAlertMessages['noPositionAvailable'])
        setSnackbarOpen(true)
        return prevDraftTeam // Return previous state without changes
      }

      const newInBankAfterAddingPlayer = calculateNewInBankAfterAddingPlayer(
        newDraftTeam.in_bank,
        rowData.now_cost
      )
      const newTeamValue = calculateNewTeamValueAfterAddingPlayer(
        newDraftTeam.team_value,
        rowData.now_cost
      )
      const newTeamAvgOwnership = calculateNewTeamAvgOwnershipAfterAddingPlayer(
        newDraftTeam,
        rowData.selected_by_percent
      )

      // // Check if enough money in bank
      // if (newInBankAfterAddingPlayer < 0) {
      //   setSnackbarMessage(addPlayerAlertMessages['noFunds'])
      //   setSnackbarOpen(true)
      //   return prevDraftTeam
      // }

      // Check if 3 players already from same team
      const playersFromSameTeam = getPlayers({
        draftTeam: newDraftTeam,
        teamFilter: [rowData.team_name],
        onlyAdded: true
      })

      if (playersFromSameTeam.length >= 3) {
        setSnackbarMessage(addPlayerAlertMessages['maxPlayersFromSameTeam'])
        setSnackbarOpen(true)
        return prevDraftTeam
      }

      // Add new player
      const positionIndex = newDraftTeam.players.findIndex(
        player => player.position === rowData.position && player.id === null
      )

      // find player object from teaminfo.picks.players matching transferred player id
      const playerAlreadyInOriginalTeam = teamInfo.picks.players.find(
        player => player.id === rowData.player_id
      )

      if (playerAlreadyInOriginalTeam) {
        newDraftTeam.players[positionIndex] = {
          id: playerAlreadyInOriginalTeam.id,
          name: playerAlreadyInOriginalTeam.name,
          team: playerAlreadyInOriginalTeam.team,
          position: playerAlreadyInOriginalTeam.position,
          stats: {
            now_cost: playerAlreadyInOriginalTeam.stats.now_cost,
            ep_this: playerAlreadyInOriginalTeam.stats.ep_this,
            form: playerAlreadyInOriginalTeam.stats.form,
            selected_by_percent:
              playerAlreadyInOriginalTeam.stats.selected_by_percent,
            purchase_price: playerAlreadyInOriginalTeam.stats.purchase_price,
            selling_price: playerAlreadyInOriginalTeam.stats.selling_price
          },
          in_main_team: newDraftTeam.players[positionIndex].in_main_team,
          is_captain: newDraftTeam.players[positionIndex].is_captain,
          is_vice_captain: newDraftTeam.players[positionIndex].is_vice_captain,
          position_id: newDraftTeam.players[positionIndex].position_id
        }
      } else {
        newDraftTeam.players[positionIndex] = {
          id: rowData.player_id,
          name: rowData.web_name,
          team: rowData.team_name,
          position: rowData.position,
          stats: {
            now_cost: rowData.now_cost,
            ep_this: rowData.ep_this,
            form: rowData.form,
            selected_by_percent: rowData.selected_by_percent,
            purchase_price: null,
            selling_price: null
          },
          in_main_team: newDraftTeam.players[positionIndex].in_main_team,
          is_captain: newDraftTeam.players[positionIndex].is_captain,
          is_vice_captain: newDraftTeam.players[positionIndex].is_vice_captain,
          position_id: newDraftTeam.players[positionIndex].position_id
        }
      }

      // Updating manager summary
      newDraftTeam.in_bank = newInBankAfterAddingPlayer
      setDraftBudget(newInBankAfterAddingPlayer)
      newDraftTeam.team_value = newTeamValue
      newDraftTeam.avg_own = newTeamAvgOwnership

      const transfersLeftTransfersCost = calculateTransfersLeftTransfersCost(
        teamInfo,
        newDraftTeam
      )

      newDraftTeam.transfers_left = transfersLeftTransfersCost.transfersLeft
      newDraftTeam.transfers_cost = transfersLeftTransfersCost.transfersCost

      return newDraftTeam
    })
    if (isSm || isXs) {
      setCurrentRightPanelTab('draft')
    }
    setSaveDraftTrigger(true)
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }

  return (
    <Box
      sx={{
        border: `0.5px solid ${grey[800]}`,
        borderRadius: '0.5em',
        marginTop: { xs: '4px', sm: '4px', md: '4px' },
        width: '100%'
        // minWidth: { xs: '100%', sm: '100%', md: '50vw' },
        // maxWidth: { xs: '100%', sm: '100%', md: '55vw' }
      }}
    >
      <MaterialTable
        style={{
          borderRadius: '0.5em'
        }}
        title={null}
        columns={columns.map(x => ({ ...x }))}
        data={playersTableData}
        isLoading={loading}
        localization={{
          toolbar: {
            nRowsSelected: '{0} player(s) selected',
            searchPlaceholder: 'Search Player'
          },
          body: {
            emptyDataSourceMessage: 'No players found'
          },
          pagination: {
            labelRowsPerPage: null,
            labelRowsSelect: 'Players',
            labelDisplayedRows: ''
          },
          header: {
            actions: null
          }
        }}
        options={{
          // tableLayout: 'fixed',
          // fixedColumns: {
          //   left: 1,
          //   right: 0
          // },
          actionsCellStyle: {
            backgroundColor: transferMode ? '#36454F' : 'black',
            position: 'sticky',
            left: 0,
            zIndex: 1
          },
          paging: true,
          padding: 'dense',
          pageSizeOptions: [5, 10, 25, 50, 100],
          showFirstLastPageButtons: false,
          paginationPosition: 'bottom',
          pageSize: 100,
          paginationType: 'stepped',
          filtering: true,
          rowStyle: {
            fontSize: fontSize,
            backgroundColor: grey[900]
          },
          headerStyle: {
            marginBottom: '1em',
            fontSize: headerFontSize,
            backgroundColor: 'black',
            position: 'sticky',
            top: 0,
            zIndex: 2,
            whiteSpace: 'nowrap'
          },
          searchFieldAlignment: 'left',
          searchFieldStyle: {
            fontSize: '14px',
            width: '80%',
            marginLeft: '2em'
          },
          searchAutoFocus: false,
          selection: false,
          showSelectAllCheckbox: false,
          showEmptyDataSourceMessage: true,
          columnsButton: true,
          maxBodyHeight: '600px'
        }}
        actions={[
          {
            icon: () => (
              <IconButton>
                <PersonAddAlt1Icon
                  fontSize='medium'
                  // sx={{ color: transferMode ? 'white' : grey[800] }}
                />
              </IconButton>
            ),
            tooltip: 'Add Player',
            onClick: (event, rowData) =>
              transferMode
                ? handleAddPlayerTransferMode(event, rowData)
                : handleAddPlayer(event, rowData)
          }
        ]}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={CONFIG['snackbarAutoHideDuration']}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.severity}
          sx={{ width: '100%', zIndex: 1200 }}
          elevation={6}
          variant='filled'
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}
