import * as React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import { grey } from '@mui/material/colors'
import { Box } from '@mui/material'
import { Typography } from '@mui/material'

export default function StatSelector () {
  const {
    statCategoryForPlayersTable,
    setStatCategoryForPlayersTable,
    formGameweeksForPlayersTable,
    setFormGameweeksForPlayersTable,
    xpGameweeksForPlayersTable,
    setXpGameweeksForPlayersTable,
    formStatForPlayersTable,
    setFormStatForPlayersTable,
    xpStatForPlayersTable,
    setXpStatForPlayersTable,
    playersRankingsTableStatsInfo
  } = useDraftsSharedState()

  const handleStatChange = event => {
    const category = playersRankingsTableStatsInfo.find(
      stat => stat.key === event.target.value
    ).category
    setStatCategoryForPlayersTable(category)

    if (category === 'expected_points') {
      setXpStatForPlayersTable(event.target.value)
    }
    if (category === 'form') {
      setFormStatForPlayersTable(event.target.value)
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        justifyItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: { xs: '2px', sm: '4px', md: '4px' }
      }}
    >
      {/* <FormControl
        size='small'
        variant='filled'
        sx={{
          margin: theme => theme.spacing(0.5),
          width: { xs: '140px', sm: '180px', md: '180px' },
          backgroundColor: grey[900],
          border: '1px solid grey',
          borderRadius: '0.2em',
          fontWeight: '400'
        }}
      >
        <InputLabel
          size='large'
          sx={{
            color: 'white',
            fontWeight: '600',
            fontSize: { xs: '10px', sm: '14px', md: '16px' }
          }}
        >
          Stat Category
        </InputLabel>
        <Select
          size='small'
          value={statCategoryForPlayersTable}
          onChange={e => setStatCategoryForPlayersTable(e.target.value)}
          sx={{
            width: '100%',
            fontSize: { xs: '10px', sm: '14px', md: '16px' },
            backgroundColor: grey[800],
            color: grey[300],
            padding: '1px 0'
          }}
        >
          <MenuItem value='expected_points'>Expected Points</MenuItem>
          <MenuItem value='form'>Form</MenuItem>
        </Select>
      </FormControl> */}
      <FormControl
        size='small'
        variant='filled'
        sx={{
          margin: theme => theme.spacing(0.5),
          width: { xs: '140px', sm: '180px', md: '180px' },
          backgroundColor: grey[900],
          border: '1px solid grey',
          borderRadius: '0.2em',
          fontWeight: '400'
        }}
      >
        <InputLabel
          size='large'
          sx={{
            color: 'white',
            fontWeight: '600',
            fontSize: { xs: '10px', sm: '14px', md: '16px' }
          }}
        >
          Sort by
        </InputLabel>
        <Select
          value={
            statCategoryForPlayersTable === 'form'
              ? formStatForPlayersTable
              : xpStatForPlayersTable
          }
          onChange={e => handleStatChange(e)}
          // disabled={statCategoryForPlayersTable === 'expected_points'}
          sx={{
            width: '100%',
            fontSize: { xs: '10px', sm: '14px', md: '16px' },
            backgroundColor: grey[800],
            color: grey[300],
            padding: '1px 0'
          }}
        >
          {playersRankingsTableStatsInfo?.map(stat => (
            <MenuItem value={stat.key} key={stat.key}>
              {stat.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {statCategoryForPlayersTable === 'form' && (
        <FormControl
          size='small'
          variant='filled'
          sx={{
            margin: theme => theme.spacing(0.5),
            width: { xs: '140px', sm: '180px', md: '180px' },
            backgroundColor: grey[900],
            border: '1px solid grey',
            borderRadius: '0.2em',
            fontWeight: '400'
          }}
        >
          <InputLabel
            size='large'
            sx={{
              color: 'white',
              fontWeight: '600',
              fontSize: { xs: '10px', sm: '14px', md: '16px' }
            }}
          >
            Gameweeks
          </InputLabel>
          <Select
            value={formGameweeksForPlayersTable}
            onChange={e => setFormGameweeksForPlayersTable(e.target.value)}
            sx={{
              width: '100%',
              fontSize: { xs: '10px', sm: '14px', md: '16px' },
              backgroundColor: grey[800],
              color: grey[300],
              padding: '1px 0'
            }}
          >
            <MenuItem value='overall'>Season Overall</MenuItem>
            <MenuItem value='3'>Last 3 GWs</MenuItem>
            <MenuItem value='5'>Last 5 GWs</MenuItem>
          </Select>
        </FormControl>
      )}
      {statCategoryForPlayersTable === 'expected_points' && (
        <FormControl
          size='small'
          variant='filled'
          sx={{
            margin: theme => theme.spacing(0.5),
            width: { xs: '140px', sm: '180px', md: '180px' },
            backgroundColor: grey[900],
            border: '1px solid grey',
            borderRadius: '0.2em',
            fontWeight: '400'
          }}
        >
          <InputLabel
            size='large'
            sx={{
              color: 'white',
              fontWeight: '600',
              fontSize: { xs: '10px', sm: '14px', md: '16px' }
            }}
          >
            Gameweeks
          </InputLabel>
          <Select
            value={xpGameweeksForPlayersTable}
            onChange={e => setXpGameweeksForPlayersTable(e.target.value)}
            sx={{
              width: '100%',
              fontSize: { xs: '10px', sm: '14px', md: '16px' },
              backgroundColor: grey[800],
              color: grey[300],
              padding: '1px 0'
            }}
          >
            <MenuItem value='next'>Next GW</MenuItem>
            <MenuItem value='3'>Next 3 GWs</MenuItem>
            <MenuItem value='5'>Next 5 GWs</MenuItem>
          </Select>
        </FormControl>
      )}
    </Box>
  )
}
