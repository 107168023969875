import './App.css'
import * as React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'

import { Analytics } from '@vercel/analytics/react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ProtectedRoute from 'utils/ProtectedRoute'
import MainApp from 'MainApp'
import Login from 'pages/login/Login'
import LoginSuccess from 'pages/login/LoginSuccess'
import Register from 'pages/register/Register'
import Confirmation from 'pages/confirmation/Confirmation'
import AfterConfirmation from 'pages/after_confirmation/AfterConfirmation'
import SetNewPassword from 'pages/set_new_password/SetNewPassword'

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 2000,
      xl: 2400
    }
  }
})

function App () {
  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Routes>
          <Route
            path='/*'
            element={
              <ProtectedRoute>
                <MainApp />
              </ProtectedRoute>
            }
          />
          <Route path='/login/' element={<Login />} />
          <Route path='/login-success/' element={<LoginSuccess />} />
          <Route path='/register/' element={<Register />} />
          <Route path='/confirm/' element={<Confirmation />} />
          <Route path='/after-confirm/' element={<AfterConfirmation />} />
          <Route path='/set-password/' element={<SetNewPassword />} />
        </Routes>
        <Analytics />
      </ThemeProvider>
    </>
  )
}

export default App
