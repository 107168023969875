import React from 'react'
import { Box, MenuItem, Select, Typography, Card } from '@mui/material'
import grey from '@mui/material/colors/grey'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import CONFIG from 'config.json'

export default function PlayerInfoSelectButton () {
  const { playerInfoRadioValue, setPlayerInfoRadioValue, draftsList } =
    useDraftsSharedState()

  const [open, setOpen] = React.useState(false)

  const handleChange = event => {
    setPlayerInfoRadioValue(event.target.value)
  }

  const handleToggle = () => {
    setOpen(!open)
  }

  const playerInfoOptions = CONFIG['playerInfoRadioButtonOptions']

  return (
    <>
      {draftsList.length > 0 && (
        <Box
          sx={{
            backgroundColor: grey[900],
            width: { xs: '115px', sm: '180px', md: '200px' },
            // margin: '0.5em',
            padding: '0.5em',
            position: 'absolute',
            borderRadius: '0.5em',
            top: { xs: '8px', sm: '12px', md: '14px' },
            left: { xs: '6px', sm: '12px', md: '10px' }
          }}
        >
          <Box>
            <Typography
              variant='body2'
              sx={{
                fontWeight: 500,
                // display: 'inline',
                fontSize: { xs: '9.5px', sm: '12px', md: '14px' }
              }}
            >
              Show
            </Typography>
            <Select
              size='small'
              value={playerInfoRadioValue}
              onChange={handleChange}
              open={open}
              onClose={() => setOpen(false)}
              onOpen={handleToggle}
              displayEmpty
              renderValue={selected => {
                const selectedOption = playerInfoOptions.find(
                  option => option.field === selected
                )
                return selectedOption ? selectedOption.label : ''
              }}
              sx={{
                width: '100%',
                backgroundColor: grey[800],
                color: 'white',
                fontSize: { xs: '9.5px', sm: '12px', md: '14px' }
              }}
            >
              {playerInfoOptions.map(option => (
                <MenuItem
                  key={option.field}
                  value={option.field}
                  sx={{ fontSize: { xs: '9.5px', sm: '12px', md: '14px' } }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      )}
    </>
  )
}
