import { Box } from '@mui/material'
import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { ROUTES } from 'utils/globalSettings'
import Snackbar from '@mui/material/Snackbar'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Alert from '@mui/material/Alert'
import CONFIG from 'config.json'
import axiosService from 'utils/axios'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import { grey } from '@mui/material/colors'

export default function SyncDraftButton () {
  const { selectedDraftId, setDraftsList, draftsList } = useDraftsSharedState()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: '',
    severity: ''
  })
  const [isSyncDialogOpen, setIsSyncDialogOpen] = useState(false)

  
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }
  const confirmSync = () => {
    setIsSyncDialogOpen(false)
    axiosService.post(ROUTES.syncDraft + selectedDraftId + '/').then(res => {
      axiosService.get(ROUTES.getDraftsList).then(response => {
        setDraftsList(response.data)

        setSnackbarMessage({
          message: 'Draft reset to last GW team!',
          severity: 'success'
        })
      })

      setSnackbarOpen(true)
    })
  }

  const handleSync = () => {
    setIsSyncDialogOpen(true)
  }

  const handleSyncDialogClose = () => {
    setIsSyncDialogOpen(false)
  }

  return (
    <>
      {draftsList.length > 0 && (
        <Box>
          <Button
            variant='contained'
            size='small'
            color='primary'
            onClick={handleSync}
            sx={{
              textWrap: 'nowrap',
              fontSize: { xs: '12px', sm: '14px', md: '16px' },
              padding: { xs: '0.2em', sm: '0.4em', md: '0.5em' },
              height: { xs: '40px', sm: '44px', md: '44px' },
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
              position: 'absolute',
              top: { xs: '8px', sm: '12px', md: '14px' },
              right: { xs: '6px', sm: '12px', md: '10px' },
              backgroundColor: grey[900],
              color: 'white',
              borderRadius: '0.5em'
            }}
          >
            Sync Draft
          </Button>

          <Dialog open={isSyncDialogOpen} onClose={handleSyncDialogClose}>
            <DialogTitle>
              Are you sure you want to reset this draft to your last gameweek
              team?
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleSyncDialogClose}>Cancel</Button>
              <Button onClick={confirmSync}>Confirm</Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={CONFIG['snackbarAutoHideDuration']}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbarMessage.severity}
              sx={{ width: '100%' }}
              elevation={6}
              variant='filled'
            >
              {snackbarMessage.message}
            </Alert>
          </Snackbar>
        </Box>
      )}
    </>
  )
}
