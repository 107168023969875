import * as React from 'react'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import grey from '@mui/material/colors/grey'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import SettingsIcon from '@mui/icons-material/Settings'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import SetTeamIdButtonDialog from 'components/set_team_id_button/SetTeamIdButtonDialog'
import axiosService from 'utils/axios'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'utils/globalSettings'
import { useAppSharedState } from 'state/AppSharedContext'
import Chip from '@mui/material/Chip'
import Brand from './Brand'
import Slide from '@mui/material/Slide'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import PropTypes from 'prop-types'

function HideOnScroll (props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined
  })

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children ?? <div />}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
}
export default function TopNavBar (props) {
  const { teamInfo, setTeamIdDialogOpen, fplTeamId } = useAppSharedState()
  const navigate = useNavigate()
  const [anchorElUser, setAnchorElUser] = React.useState(null)

  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogout = () => {
    const auth = JSON.parse(localStorage.getItem('auth'))
    const refreshToken = auth.tokens.refresh
    axiosService
      .post(ROUTES.logout, { refresh_token: refreshToken })
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        handleCloseUserMenu()
        localStorage.removeItem('auth')
        navigate('/login/')
      })
  }

  const handleSetTeamId = () => {
    setTeamIdDialogOpen(true)
    handleCloseUserMenu()
  }

  const settings = [
    {
      key: 'set_team_id',
      label: 'Set Team ID',
      on_click: handleSetTeamId
    },
    {
      key: 'logout',
      label: 'Logout',
      on_click: handleLogout
    }
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: grey[800],
        height: { xs: '60px', sm: '60px', md: '60px' }
      }}
    >
      {/* <HideOnScroll {...props}> */}
      <AppBar
        sx={{
          position: 'fixed',
          top: 0
        }}
      >
        <Toolbar>
          <Brand />

          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 0.2 }} /> */}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              flexGrow: 1,
              alignContent: 'center',
              alignItems: 'center',
              height: { xs: '60px', sm: '60px', md: '60px' }
            }}
          >
            {fplTeamId && (
              <Chip
                label={'Team ID : ' + fplTeamId}
                sx={{
                  ml: '0.5em',
                  mr: '0.5em',
                  alignContent: 'center',
                  display: { xs: 'block', sm: 'block' }
                }}
              />
            )}
            {teamInfo.summary && (
              <Chip
                label={'Team : ' + teamInfo.summary.team_name}
                sx={{
                  ml: '0.5em',
                  mr: '0.5em',
                  alignContent: 'center',
                  display: { xs: 'none', sm: 'block' }
                }}
              />
            )}
            {teamInfo.summary && teamInfo.summary.rank_short_form && (
              <Chip
                label={'Rank : ' + teamInfo.summary.rank_short_form}
                sx={{
                  ml: '0.5em',
                  mr: '0.5em',
                  alignContent: 'center',
                  display: { xs: 'none', sm: 'none', md: 'block' }
                }}
              />
            )}
            {/* {teamInfo.summary && (
              <Chip
                label={'Points : ' + teamInfo.summary.total_points}
                sx={{ ml: '0.5em', mr: '0.5em' }}
              />
            )} */}
            {teamInfo.summary && (
              <Chip
                label={'Top ' + teamInfo.summary.top_x_percent + '%'}
                sx={{
                  ml: '0.5em',
                  mr: '0.5em',
                  alignContent: 'center',
                  display: { xs: 'none', sm: 'none', md: 'block' }
                }}
              />
            )}
            {teamInfo.summary && (
              <Chip
                label={'Avg Team Ownership : ' + teamInfo.summary.avg_own + '%'}
                sx={{
                  ml: '0.5em',
                  mr: '0.5em',
                  alignContent: 'center',
                  display: { xs: 'none', sm: 'none', md: 'block' }
                }}
              />
            )}
          </Box>

          {/* <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              ml: '0.5em',
              mr: '0.5em'
            }}
          >
            <SetTeamIdButton
              sx={{ marginLeft: '0.5em', marginRight: '0.5em' }}
            />
            <Button
              sx={{ marginLeft: '0.5em', marginRight: '0.5em' }}
              variant='text'
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Box> */}
          <SetTeamIdButtonDialog />
          <Box sx={{}}>
            <Tooltip title='Open settings'>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <SettingsIcon />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map(setting => (
                <MenuItem key={setting.key} onClick={setting.on_click}>
                  <Typography textAlign='center'>{setting.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      {/* </HideOnScroll> */}
    </Box>
  )
}
