import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import grey from '@mui/material/colors/grey'
import CircularProgress from '@mui/material/CircularProgress'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import { useAppSharedState } from 'state/AppSharedContext'

export default function OwnershipRangeInput () {
  const { gameweekInfo } = useAppSharedState()
  const { ownershipSliderValue, setOwnershipSliderValue } =
    useDraftsSharedState()

  const step = 5
  const [ownershipOptions, setOwnershipOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  // Effect to re-calculate price options and handle re-renders when min/max changes
  useEffect(() => {
    if (
      gameweekInfo.min_selected_by_percent !== undefined &&
      gameweekInfo.max_selected_by_percent !== undefined
    ) {
      setIsLoading(false)

      let options = []
      for (
        let ownership =
          Math.ceil(gameweekInfo.min_selected_by_percent / step) * step;
        ownership < gameweekInfo.max_selected_by_percent;
        ownership += step
      ) {
        options.push(ownership.toFixed(1))
      }

      if (!options.includes(gameweekInfo.min_selected_by_percent.toFixed(1))) {
        options.unshift(gameweekInfo.min_selected_by_percent.toFixed(1))
      }
      if (!options.includes(gameweekInfo.max_selected_by_percent.toFixed(1))) {
        options.push(gameweekInfo.max_selected_by_percent.toFixed(1))
      }

      setOwnershipOptions(options)
    }
  }, [
    gameweekInfo.min_selected_by_percent,
    gameweekInfo.max_selected_by_percent
  ])

  const [minOwnership, maxOwnership] = ownershipSliderValue // Split the current price range

  // Handle changes for min and max price dropdowns
  const handleMinOwnershipChange = event => {
    const newMin = event.target.value
    if (newMin <= maxOwnership) {
      setOwnershipSliderValue([parseFloat(newMin), maxOwnership])
    }
  }

  const handleMaxOwnershipChange = event => {
    const newMax = event.target.value
    if (newMax >= minOwnership) {
      setOwnershipSliderValue([minOwnership, parseFloat(newMax)])
    }
  }

  return (
    <Box
      sx={{
        border: `1px solid ${grey[700]}`,
        borderRadius: '0.5em',
        padding: '0.5em',
        width: { xs: '200px', sm: '220px', md: '250px' }, // Responsive width
        // Responsive width
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {isLoading ? (
        // Show a loading spinner while fetching the prices
        <CircularProgress size={24} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '45%'
            }}
          >
            <Typography
              variant='body2'
              sx={{
                fontSize: { xs: '10px', sm: '12px', md: '14px' },
                color: 'white',
                textWrap: 'nowrap'
              }}
            >
              Min Ownership
            </Typography>
            <Select
              size='small'
              value={minOwnership}
              onChange={handleMinOwnershipChange}
              sx={{
                width: '100%',
                fontSize: { xs: '10px', sm: '14px', md: '16px' },
                backgroundColor: grey[800],
                color: 'white'
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: { xs: '400px', sm: '500px', md: '600px' } // Limit the dropdown height (adjust as needed)
                  }
                }
              }}
            >
              {ownershipOptions.map(ownership => (
                <MenuItem
                  key={ownership}
                  value={parseFloat(ownership)}
                  sx={{ fontSize: { xs: '10px', sm: '14px', md: '16px' } }}
                >
                  {ownership}%
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '45%'
            }}
          >
            <Typography
              variant='body2'
              sx={{
                fontSize: { xs: '10px', sm: '12px', md: '14px' },
                color: 'white',
                textWrap: 'nowrap'
              }}
            >
              Max Ownership
            </Typography>
            <Select
              size='small'
              value={maxOwnership}
              onChange={handleMaxOwnershipChange}
              sx={{
                width: '100%',
                fontSize: { xs: '10px', sm: '14px', md: '16px' },
                backgroundColor: grey[800],
                color: 'white'
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: { xs: '400px', sm: '500px', md: '600px' } // Limit the dropdown height (adjust as needed)
                  }
                }
              }}
            >
              {ownershipOptions.map(ownership => (
                <MenuItem
                  key={ownership}
                  value={parseFloat(ownership)}
                  sx={{ fontSize: { xs: '10px', sm: '14px', md: '16px' } }}
                >
                  {ownership}%
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      )}
    </Box>
  )
}
