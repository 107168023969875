import TopNavBar from 'components/top_nav_bar/TopNavBar'
import SideNavBar from 'components/side_nav_bar/SideNavBar'
import Drafts from 'pages/drafts/Drafts'
import Box from '@mui/material/Box'
import { AppSharedStateProvider } from 'state/AppSharedContext'
import { DraftsSharedStateProvider } from 'state/DraftsSharedContext'
import { Route, Routes, Navigate } from 'react-router-dom'
import WorkInProgress from 'pages/work_in_progress/WorkInProgress'

export default function MainApp () {
  return (
    <AppSharedStateProvider>
      <DraftsSharedStateProvider>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
          <TopNavBar />
          <Box
            component='main'
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexGrow: 1,
              overflow: 'hidden'
            }}
          >
            {/* <SideNavBar /> */}
            <Routes>
              <Route
                path='/'
                element={<Navigate to='/drafts-planner/' replace />}
              />
              <Route path='/drafts-planner/' element={<Drafts />} />
              <Route path='my-team/' element={<WorkInProgress />} />
              <Route path='ai-assistant/' element={<WorkInProgress />} />
              <Route path='templates/' element={<WorkInProgress />} />
            </Routes>
          </Box>
        </Box>
      </DraftsSharedStateProvider>
    </AppSharedStateProvider>
  )
}
