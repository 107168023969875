import Box from '@mui/material/Box'
import PlayersFilterSearchBox from './players_filter_search_box/PlayersFilterSearchBox'
import PlayersTable from './PlayersTable'
import { Typography } from '@mui/material'
import { useDraftsSharedState } from 'state/DraftsSharedContext'

export default function PlayersRankings () {
  const { draftBudget } = useDraftsSharedState()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography sx={{ margin: '0.5em' }}>
        {' '}
        <strong>Transfer Budget : {draftBudget}m</strong>
      </Typography>
      {/* Filter Section */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <PlayersFilterSearchBox />
      </Box>
      <PlayersTable />
    </Box>
  )
}
