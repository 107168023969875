import './PlayerName.css'
import Typography from '@mui/material/Typography'
import grey from '@mui/material/colors/grey'
import { Box } from '@mui/material'

export default function PlayerName ({ playerInfo, size }) {
  return (
    <Box
      align='center'
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: { xs: '100%', sm: '100%', md: '100%' },
        justifyContent: 'space-between',
        backgroundColor: playerInfo.name !== null && grey[800],
        margin: '0.05em',
        padding: { xs: '0.2em', sm: '0.2em', md: '0.2em' },
        borderRadius: '0.4em',
        textWrap: 'nowrap'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          padding: 0,
          margin: 0
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: size === 'small' ? '8px' : '9.5px',
              sm: '14px',
              md: '14px'
            },
            fontWeight: playerInfo.name !== null ? 400 : 700,
            color: playerInfo.name !== null ? 'white' : grey[900],
            maxWidth: { xs: '10ch', sm: '12ch', md: '12ch' }, // Limit to 9 characters
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {playerInfo.name !== null ? playerInfo.name : playerInfo.position}
        </Typography>
        {/* <Typography
          sx={{
            fontSize: {
              xs: size === 'small' ? '8px' : '9.5px',
              sm: '12px',
              md: '12px'
            },
            fontWeight: playerInfo.name !== null ? 400 : 700,
            color: playerInfo.name !== null ? grey[400] : grey[900]
          }}
        >
          {playerInfo.name !== null ? playerInfo.team : playerInfo.team}
        </Typography> */}
      </Box>
      <Typography
        sx={{
          fontSize: {
            xs: size === 'small' ? '8px' : '9.5px',
            sm: '14px',
            md: '14px'
          },
          fontWeight: playerInfo.name !== null ? 400 : 700,
          color: playerInfo.name !== null ? grey[400] : grey[900]
        }}
      >
        {playerInfo.stats.now_cost}m
      </Typography>
    </Box>
  )
}
