import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

import { ROUTES } from 'utils/globalSettings'

const axiosService = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json'
  }
})

axiosService.interceptors.request.use(async config => {
  /**
   * Retrieving the access token from the localStorage and adding it to the headers of the request
   */
  const auth = JSON.parse(localStorage.getItem('auth'))

  if (!auth) return config
  const { access } = auth.tokens
  config.headers.Authorization = `Bearer ${access}`
  return config
})

axiosService.interceptors.response.use(
  res => Promise.resolve(res),
  err => Promise.reject(err)
)

const refreshAuthLogic = async failedRequest => {
  const auth = JSON.parse(localStorage.getItem('auth'))
  const { refresh } = auth.tokens
  return axios
    .post(ROUTES.refreshToken, null, {
      baseURL: `${process.env.REACT_APP_API_URL}`,
      headers: {
        Authorization: `Bearer ${refresh}`
      }
    })
    .then(resp => {
      const { access, refresh } = resp.data
      failedRequest.response.config.headers['Authorization'] =
        'Bearer ' + access
      auth.tokens = { access, refresh }
      localStorage.setItem('auth', JSON.stringify(auth))
    })
    .catch(() => {
      localStorage.removeItem('auth')
    })
}

createAuthRefreshInterceptor(axiosService, refreshAuthLogic)

// export function fetcher (url) {
//   return axiosService.get(url).then(res => res.data)
// }

export default axiosService
