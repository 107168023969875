import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import grey from '@mui/material/colors/grey'
import CircularProgress from '@mui/material/CircularProgress'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import { useAppSharedState } from 'state/AppSharedContext'

export default function PriceRangeSelect () {
  const { gameweekInfo } = useAppSharedState()
  const { priceSliderValue, setPriceSliderValue } = useDraftsSharedState()

  const step = 0.5
  const [priceOptions, setPriceOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  // Effect to re-calculate price options and handle re-renders when min/max changes
  useEffect(() => {
    if (
      gameweekInfo.min_player_price !== undefined &&
      gameweekInfo.max_player_price !== undefined
    ) {
      setIsLoading(false)

      // Calculate available price options
      let options = []
      for (
        let price = Math.ceil(gameweekInfo.min_player_price / step) * step;
        price < gameweekInfo.max_player_price;
        price += step
      ) {
        options.push(price.toFixed(1))
      }

      // Ensure exact min and max prices are included
      if (!options.includes(gameweekInfo.min_player_price.toFixed(1))) {
        options.unshift(gameweekInfo.min_player_price.toFixed(1))
      }
      if (!options.includes(gameweekInfo.max_player_price.toFixed(1))) {
        options.push(gameweekInfo.max_player_price.toFixed(1))
      }

      setPriceOptions(options)
    }
  }, [gameweekInfo.min_player_price, gameweekInfo.max_player_price])

  const [minPrice, maxPrice] = priceSliderValue // Split the current price range

  // Handle changes for min and max price dropdowns
  const handleMinPriceChange = event => {
    const newMin = event.target.value
    if (newMin <= maxPrice) {
      setPriceSliderValue([parseFloat(newMin), maxPrice])
    }
  }

  const handleMaxPriceChange = event => {
    const newMax = event.target.value
    if (newMax >= minPrice) {
      setPriceSliderValue([minPrice, parseFloat(newMax)])
    }
  }

  return (
    <Box
      sx={{
        border: `1px solid ${grey[700]}`,
        borderRadius: '0.5em',
        padding: '0.5em',
        width: { xs: '200px', sm: '220px', md: '250px' }, // Responsive width
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {isLoading ? (
        // Show a loading spinner while fetching the prices
        <CircularProgress size={24} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '45%'
            }}
          >
            <Typography
              variant='body2'
              sx={{
                fontSize: { xs: '10px', sm: '12px', md: '14px' },
                color: 'white'
              }}
            >
              Min Price
            </Typography>
            <Select
              size='small'
              value={minPrice}
              onChange={handleMinPriceChange}
              sx={{
                width: '100%',
                fontSize: { xs: '10px', sm: '14px', md: '16px' },
                backgroundColor: grey[800],
                color: 'white'
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: { xs: '400px', sm: '500px', md: '600px' } // Limit the dropdown height (adjust as needed)
                  }
                }
              }}
            >
              {priceOptions.map(price => (
                <MenuItem
                  key={price}
                  value={parseFloat(price)}
                  sx={{ fontSize: { xs: '10px', sm: '14px', md: '16px' } }}
                >
                  {price}m
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '45%'
            }}
          >
            <Typography
              variant='body2'
              sx={{
                fontSize: { xs: '10px', sm: '12px', md: '14px' },
                color: 'white'
              }}
            >
              Max Price
            </Typography>
            <Select
              size='small'
              value={maxPrice}
              onChange={handleMaxPriceChange}
              sx={{
                width: '100%',
                fontSize: { xs: '10px', sm: '14px', md: '16px' },
                backgroundColor: grey[800],
                color: 'white'
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: { xs: '400px', sm: '500px', md: '600px' } // Limit the dropdown height (adjust as needed)
                  }
                }
              }}
            >
              {priceOptions.map(price => (
                <MenuItem
                  key={price}
                  value={parseFloat(price)}
                  sx={{ fontSize: { xs: '10px', sm: '14px', md: '16px' } }}
                >
                  {price}m
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      )}
    </Box>
  )
}
