import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { useAppSharedState } from 'state/AppSharedContext'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import axiosService from 'utils/axios'
import { ROUTES } from 'utils/globalSettings'
import CONFIG from 'config.json'

export default function SetTeamIdButtonDialog () {
  const { fplTeamId, setFplTeamId, teamIdDialogOpen, setTeamIdDialogOpen } =
    useAppSharedState()
  const { draftsList, setDraftsList, setSelectedDraftId, selectedDraftId } =
    useDraftsSharedState()
  const [draftType, setDraftType] = useState('last_gw')
  const [draftName, setDraftName] = useState(
    'Draft #' + (draftsList.length + 1)
  )
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: '',
    severity: ''
  })
  const [teamId, setTeamId] = useState('') // State to hold the value of the text field
  const [newSelectedDraftId, setNewSelectedDraftId] = useState(null)


  useEffect(() => {
    if (newSelectedDraftId) {
      // Perform actions dependent on selectedDraftId
      console.log('New Selected Draft ID (local state):', newSelectedDraftId)
      setSelectedDraftId(newSelectedDraftId)
      getNewDraftsList()

      setTimeout(() => {
        window.location.reload()
      }, 500)
    }
  }, [newSelectedDraftId])

  useEffect(() => {
    if (fplTeamId !== null) {
      setTeamId(fplTeamId) // Update teamId when fplTeamId changes
    }
  }, [fplTeamId])

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }

  const getNewDraftsList = () => {
    axiosService.get(ROUTES.getDraftsList).then(response => {
      setDraftsList(response.data)
    })
  }

  const handleCreateDraft = () => {
    axiosService
      .post(ROUTES.createDraft, {
        name: draftName,
        base: draftType
      })
      .then(response => {
        const newDraftId = response.data.id
        if (newDraftId) {
          setNewSelectedDraftId(newDraftId) // Update the state
          setSnackbarMessage({
            message: 'Draft created successfully!',
            severity: 'success'
          })
          setSnackbarOpen(true)
        } else {
          setSnackbarMessage({
            message: 'Draft created, but no ID returned!',
            severity: 'warning'
          })
          setSnackbarOpen(true)
        }
      })
      .catch(error => {
        setSnackbarMessage({
          message: 'Failed to create draft! Please try again.',
          severity: 'error'
        })
        setSnackbarOpen(true)
      })
  }

  const handleSetTeamId = () => {
    axiosService
      .post(ROUTES.setTeamId, { team_id: teamId })
      .then(() => {
        setFplTeamId(teamId) // Update Team ID
        setSnackbarMessage({
          message:
            'Team ID saved successfully, Press sync button if team is not latest',
          severity: 'success'
        })
        setSnackbarOpen(true)
        setTeamIdDialogOpen(false) // Close dialog
        handleCreateDraft() // Call draft creation after setting Team ID
      })
      .catch(() => {
        setSnackbarMessage({
          message: 'Invalid Team ID! Try Again with a valid Team ID',
          severity: 'error'
        })
        setSnackbarOpen(true)
      })
  }

  const handleDialogClose = () => {
    setTeamIdDialogOpen(false)
  }

  return (
    <>
      <Dialog
        open={teamIdDialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle>Set Your FPL Team ID</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            id='team-id'
            label='Team ID'
            type='number'
            fullWidth
            variant='outlined'
            value={teamId}
            onChange={e => setTeamId(e.target.value)}
            helperText='Enter your Fantasy Premier League Team ID'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color='secondary'>
            Cancel
          </Button>
          <Button onClick={handleSetTeamId} variant='contained' color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={CONFIG['snackbarAutoHideDuration']}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.severity}
          sx={{ width: '100%' }}
          elevation={6}
          variant='filled'
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>
    </>
  )
}
