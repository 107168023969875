// DraftsSharedContext.js
import { createContext, useContext, useState, useEffect } from 'react'
import { useAppSharedState } from 'state/AppSharedContext'
import axiosService from 'utils/axios'
import CONFIG from 'config.json'
import { ROUTES } from 'utils/globalSettings'
import { original } from '@reduxjs/toolkit'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const SharedStateContext = createContext()

export const DraftsSharedStateProvider = ({ children }) => {
  const theme = useTheme()

  const isXs = useMediaQuery(theme.breakpoints.only('xs'))
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))
  const defaultTab = isXs ? 'draft' : isSm ? 'draft' : 'players_rankings'
  const [currentRightPanelTab, setCurrentRightPanelTab] = useState(defaultTab)

  const { gameweekInfo, fplTeamId, draftsPageLoader, setDraftsPageLoader } =
    useAppSharedState()
  const [draftsList, setDraftsList] = useState([])
  const [playerInfoRadioValue, setPlayerInfoRadioValue] = useState(
    CONFIG['defaultPlayerInfoRadioValue']
  )
  const [priceSliderValue, setPriceSliderValue] = useState([null, null])
  const [ownershipSliderValue, setOwnershipSliderValue] = useState([null, null])
  const [appliedPriceSliderValue, setAppliedPriceSliderValue] = useState([
    null,
    null
  ])
  const [playersTableData, setPlayersTableData] = useState([])
  const [originalPlayersData, setOriginalPlayersData] = useState([])
  const [teamsTableData, setTeamsTableData] = useState([])
  const [teamsTableColumns, setTeamsTableColumns] = useState([])
  const [numFixturesTeamsTable, setNumFixturesTeamsTable] = useState(
    CONFIG['defaultNumFixtures']
  )
  const [highlightedPlayerIds, setHighlightedPlayerIds] = useState([])
  const [playerSubstituteCandidateId, setPlayerSubstituteCandidateId] =
    useState(null)
  const [selectedDraftCaptainPositionId, setSelectedDraftCaptainPositionId] =
    useState(null)
  const [
    selectedDraftViceCaptainPositionId,
    setSelectedDraftViceCaptainPositionId
  ] = useState(null)
  const [selectedDraftId, setSelectedDraftId] = useState(null)
  const [selectedDraft, setSelectedDraft] = useState({})
  const [positionIdToTransfer, setPositionIdToTransfer] = useState(null)
  const [transferMode, setTransferMode] = useState(false)
  const [transferModeEmptyTeam, setTransferModeEmptyTeam] = useState(false)
  const [teamsSelectedForPlayersTable, setTeamsSelectedForPlayersTable] =
    useState([])
  const [
    nonInjuredPlayersOnlyForPlayersTable,
    setNonInjuredPlayersOnlyForPlayersTable
  ] = useState(true)
  const [
    showPlayersWithHighXminsForPlayersTable,
    setShowPlayersWithHighXminsForPlayersTable
  ] = useState(true)
  const [statCategoryForPlayersTable, setStatCategoryForPlayersTable] =
    useState('expected_points')
  const [
    positionsSelectedForPlayersTable,
    setPositionsSelectedForPlayersTable
  ] = useState([])
  const [playersTableApplyTrigger, setPlayersTableApplyTrigger] =
    useState(false)
  const [formStatForPlayersTable, setFormStatForPlayersTable] =
    useState('expected_goals')
  const [xpStatForPlayersTable, setXpStatForPlayersTable] = useState('xp')
  const [formGameweeksForPlayersTable, setFormGameweeksForPlayersTable] =
    useState('overall')
  const [xpGameweeksForPlayersTable, setXpGameweeksForPlayersTable] =
    useState('next')
  const [playersRankingsTableStatsInfo, setPlayersRankingsTableStatsInfo] =
    useState([])
  const [saveDraftTrigger, setSaveDraftTrigger] = useState(false)
  const [draftBudget, setDraftBudget] = useState(0)

  const getAllPlayersData = () => {
    if (originalPlayersData.length > 0) return
    const minPrice = gameweekInfo.min_player_price
    const maxPrice = gameweekInfo.max_player_price

    if (minPrice === undefined && maxPrice === undefined) {
      return
    }

    if (minPrice === null || maxPrice === null) {
      return
    }

    const queryPayload = {
      min_price: minPrice,
      max_price: maxPrice,
      teams: [],
      positions: []
    }

    axiosService
      .post(ROUTES.playersList, queryPayload)
      .then(response => {
        setOriginalPlayersData(response.data)
      })
      .catch(error => {
        console.error('Error fetching data:', error)
      })
  }

  useEffect(() => {
    if (Object.keys(gameweekInfo).length === 0) return

    if (originalPlayersData.length === 0) return

    if (draftsList.length === 0) return

    setDraftsPageLoader(false)
  }, [gameweekInfo, originalPlayersData, draftsList])

  useEffect(() => {
    if (originalPlayersData.length === 0) {
      getAllPlayersData()
    }
  }, [gameweekInfo])

  useEffect(() => {
    setPriceSliderValue([
      gameweekInfo.min_player_price,
      gameweekInfo.max_player_price
    ])
    setOwnershipSliderValue([
      gameweekInfo.min_selected_by_percent,
      gameweekInfo.max_selected_by_percent
    ])
  }, [gameweekInfo])

  useEffect(() => {
    if (fplTeamId === null) return

    if (draftsList.length > 0) return

    axiosService.get(ROUTES.getDraftsList).then(response => {
      setDraftsList(response.data)

      if (response.data.length > 0) {
        setSelectedDraftId(response.data[0].id)
      }
    })
  }, [fplTeamId])

  useEffect(() => {
    axiosService
      .get(ROUTES.playersRankingTableStats)
      .then(response => {
        setPlayersRankingsTableStatsInfo(response.data)
      })
      .catch(error => {
        console.error('Error fetching data:', error)
      })
  }, [fplTeamId])

  useEffect(() => {
    if (draftsList.length === 0) {
      setSelectedDraft({})
      setSelectedDraftCaptainPositionId(null)
      setSelectedDraftViceCaptainPositionId(null)
      return
    }
    if (selectedDraftId === undefined) return
    if (selectedDraftId === null) return

    const draftTeam = draftsList.find(draft => draft.id === selectedDraftId)
    if (draftTeam === undefined) {
      setSelectedDraft({})
      setSelectedDraftCaptainPositionId(null)
      setSelectedDraftViceCaptainPositionId(null)
      return
    }
    setDraftBudget(draftTeam.in_bank)

    setSelectedDraft(draftTeam)
    setSelectedDraftCaptainPositionId(draftTeam.captain_position_id)
    setSelectedDraftViceCaptainPositionId(draftTeam.vice_captain_position_id)
  }, [selectedDraftId, draftsList])

  return (
    <SharedStateContext.Provider
      value={{
        playerInfoRadioValue,
        setPlayerInfoRadioValue,
        priceSliderValue,
        setPriceSliderValue,
        playersTableData,
        setPlayersTableData,
        appliedPriceSliderValue,
        setAppliedPriceSliderValue,
        teamsTableData,
        setTeamsTableData,
        teamsTableColumns,
        setTeamsTableColumns,
        numFixturesTeamsTable,
        setNumFixturesTeamsTable,
        highlightedPlayerIds,
        setHighlightedPlayerIds,
        playerSubstituteCandidateId,
        setPlayerSubstituteCandidateId,
        selectedDraftCaptainPositionId,
        setSelectedDraftCaptainPositionId,
        selectedDraftViceCaptainPositionId,
        setSelectedDraftViceCaptainPositionId,
        draftsList,
        setDraftsList,
        selectedDraftId,
        setSelectedDraftId,
        selectedDraft,
        setSelectedDraft,
        positionIdToTransfer,
        setPositionIdToTransfer,
        transferMode,
        setTransferMode,
        transferModeEmptyTeam,
        setTransferModeEmptyTeam,
        teamsSelectedForPlayersTable,
        setTeamsSelectedForPlayersTable,
        positionsSelectedForPlayersTable,
        setPositionsSelectedForPlayersTable,
        nonInjuredPlayersOnlyForPlayersTable,
        setNonInjuredPlayersOnlyForPlayersTable,
        statCategoryForPlayersTable,
        setStatCategoryForPlayersTable,
        playersTableApplyTrigger,
        setPlayersTableApplyTrigger,
        originalPlayersData,
        formGameweeksForPlayersTable,
        setFormGameweeksForPlayersTable,
        xpGameweeksForPlayersTable,
        setXpGameweeksForPlayersTable,
        ownershipSliderValue,
        setOwnershipSliderValue,
        formStatForPlayersTable,
        setFormStatForPlayersTable,
        xpStatForPlayersTable,
        setXpStatForPlayersTable,
        playersRankingsTableStatsInfo,
        setPlayersRankingsTableStatsInfo,
        showPlayersWithHighXminsForPlayersTable,
        setShowPlayersWithHighXminsForPlayersTable,
        saveDraftTrigger,
        setSaveDraftTrigger,
        currentRightPanelTab,
        setCurrentRightPanelTab,
        draftBudget,
        setDraftBudget
      }}
    >
      {children}
    </SharedStateContext.Provider>
  )
}

export const useDraftsSharedState = () => useContext(SharedStateContext)
