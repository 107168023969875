import { current } from '@reduxjs/toolkit'
import CONFIG from 'config.json'

const getPlayers = ({
  draftTeam,
  position = ['GKP', 'DEF', 'MID', 'FWD'],
  inMainTeam = [true, false],
  excludePositionId = [],
  onlyAdded = false,
  teamFilter = []
}) => {
  if (draftTeam === null) {
    return []
  }

  if (draftTeam === undefined) {
    return []
  }

  if (draftTeam.players === undefined) {
    return []
  }

  return draftTeam.players.filter(
    player =>
      position.includes(player.position) &&
      inMainTeam.includes(player.in_main_team) &&
      (onlyAdded ? player.id !== null : true) &&
      !excludePositionId.includes(player.position_id) &&
      (teamFilter.length > 0 ? teamFilter.includes(player.team) : true)
  )
}

const checkIfPlayerExists = (draftTeam, playerId) => {
  if (draftTeam === null) {
    return false
  }

  if (draftTeam === undefined) {
    return false
  }

  if (draftTeam.players === undefined) {
    return false
  }

  return draftTeam.players.some(
    player => player.id !== null && player.id === playerId
  )
}

const checkIfPositionAvailable = (draftTeam, position) => {
  if (draftTeam === null) {
    return false
  }

  if (draftTeam === undefined) {
    return false
  }

  if (draftTeam.players === undefined) {
    return false
  }

  return draftTeam.players.some(
    player => player.position === position && player.id === null
  )
}

const calculateNewInBankAfterAddingPlayer = (currentInBank, playerCost) => {
  return parseFloat((currentInBank - playerCost).toFixed(1))
}

const calculateNewInBankAfterTransferringPlayer = (
  currentInBank,
  oldPlayerSellingPrice,
  newPlayerCost
) => {
  if (oldPlayerSellingPrice === null) {
    oldPlayerSellingPrice = 0
  }

  return parseFloat(
    (currentInBank + oldPlayerSellingPrice - newPlayerCost).toFixed(1)
  )
}

const calculateNewInBankAfterSellingPlayer = (
  currentInBank,
  playerSellingPrice
) => {
  if (playerSellingPrice === null) {
    playerSellingPrice = 0
  }
  return parseFloat((currentInBank + playerSellingPrice).toFixed(1))
}

const calculateNewInBankAfterRetainingPlayer = (
  currentInBank,
  playerSellingPrice
) => {
  if (playerSellingPrice === null) {
    playerSellingPrice = 0
  }
  return parseFloat((currentInBank - playerSellingPrice).toFixed(1))
}

const calculateNewTeamValueAfterAddingPlayer = (currentValue, playerCost) => {
  return parseFloat((currentValue + playerCost).toFixed(1))
}

const calculateNewTeamValueAfterTransferringPlayer = (
  currentValue,
  oldPlayerCost,
  newPlayerCost
) => {
  if (oldPlayerCost === null) {
    oldPlayerCost = 0
  }
  return parseFloat((currentValue - oldPlayerCost + newPlayerCost).toFixed(1))
}

const calculateNewInBankAfterRemovingPlayer = (currentInBank, playerCost) => {
  return parseFloat((currentInBank + playerCost).toFixed(1))
}

const calculateNewTeamValueAfterRemovingPlayer = (currentValue, playerCost) => {
  return parseFloat((currentValue - playerCost).toFixed(1))
}

const calculateNewTeamAvgOwnershipAfterAddingPlayer = (
  draftTeam,
  playerOwnership
) => {
  if (draftTeam === null) {
    return 0
  }

  if (draftTeam === undefined) {
    return 0
  }

  if (draftTeam.players === undefined) {
    return 0
  }

  const addedPlayersCount = getPlayers({ draftTeam, onlyAdded: true }).length
  const totalOwnership = draftTeam.players.reduce(
    (acc, { stats: { selected_by_percent } }) =>
      acc +
      (selected_by_percent !== null ? parseFloat(selected_by_percent) : 0),
    0
  )

  return parseFloat(
    (
      (totalOwnership + parseFloat(playerOwnership)) /
      (addedPlayersCount + 1)
    ).toFixed(1)
  )
}

const calculateNewTeamAvgOwnershipAfterTransferringPlayer = (
  draftTeam,
  oldPlayerOwnership,
  newPlayerOwnership
) => {
  if (draftTeam === null) {
    return 0
  }

  if (draftTeam === null) {
    return 0
  }

  if (draftTeam === undefined) {
    return 0
  }

  if (draftTeam.players === undefined) {
    return 0
  }

  var addedPlayersCount = getPlayers({ draftTeam, onlyAdded: true }).length

  if (addedPlayersCount === 0) {
    return parseFloat(newPlayerOwnership)
  }

  if (oldPlayerOwnership === null) {
    oldPlayerOwnership = 0
    addedPlayersCount = addedPlayersCount + 1
  }

  const totalOwnership = draftTeam.players.reduce(
    (acc, { stats: { selected_by_percent } }) =>
      acc +
      (selected_by_percent !== null ? parseFloat(selected_by_percent) : 0),
    0
  )

  return parseFloat(
    (
      (totalOwnership -
        parseFloat(oldPlayerOwnership) +
        parseFloat(newPlayerOwnership)) /
      addedPlayersCount
    ).toFixed(1)
  )
}

const calculateNewTeamAvgOwnershipAfterRemovingPlayer = (
  draftTeam,
  playerOwnership
) => {
  if (draftTeam === null) {
    return 0
  }

  if (draftTeam === undefined) {
    return 0
  }

  if (draftTeam.players === undefined) {
    return 0
  }

  const addedPlayersCount = getPlayers({ draftTeam, onlyAdded: true }).length
  const totalOwnership = draftTeam.players.reduce(
    (acc, { stats: { selected_by_percent } }) =>
      acc +
      (selected_by_percent !== null ? parseFloat(selected_by_percent) : 0),
    0
  )
  if (addedPlayersCount === 1) {
    return 0
  } else {
    return parseFloat(
      (totalOwnership - parseFloat(playerOwnership)) / (addedPlayersCount - 1)
    ).toFixed(1)
  }
}

const findPositionWithMinimumPlayersInTheMainTeam = draftTeam => {
  if (draftTeam === null) {
    return null
  }

  if (draftTeam === undefined) {
    return null
  }

  if (draftTeam.players === undefined) {
    return null
  }

  const positions = ['DEF', 'MID', 'FWD']

  for (const position of positions) {
    if (
      getPlayers({
        draftTeam,
        position: [position],
        inMainTeam: [true]
      }).length === CONFIG['minimumAllowedPlayersInMainTeam'][position]
    ) {
      return position
    }
  }
}

const calculateTransfersLeftTransfersCost = (originalTeam, newDraftTeam) => {
  if (newDraftTeam.transfers_left === null) {
    return {
      transfersLeft: null,
      transfersCost: 0
    }
  }

  const originalPlayerIds = originalTeam.picks.players.map(player => player.id)
  const newPlayerIds = newDraftTeam.players.map(player => player.id)

  // Calculate the number of unique player IDs in newDraftTeam that are not in originalDraftTeam
  const uniqueNewPlayers = newPlayerIds.filter(
    id => !originalPlayerIds.includes(id)
  )

  if (originalTeam.summary.transfers_left === null)
    return {
      transfersLeft: null,
      transfersCost: 0
    }

  const freeTransfersLeft =
    originalTeam.summary.transfers_left - uniqueNewPlayers.length

  if (freeTransfersLeft >= 0) {
    return {
      transfersLeft: freeTransfersLeft,
      transfersCost: 0
    }
  } else {
    return {
      transfersLeft: 0,
      transfersCost: 4 * Math.abs(freeTransfersLeft)
    }
  }
}

const filterPlayers = (allPlayersData, filterSettings) => {
  const teamsFilter = filterSettings.teams
  const positionFilter = filterSettings.positions
  const minPrice = filterSettings.min_price
  const maxPrice = filterSettings.max_price
  const minOwnership = filterSettings.min_ownership
  const maxOwnership = filterSettings.max_ownership

  const filteredPlayers = allPlayersData.filter(player => {
    const teamInFilter =
      teamsFilter.length === 0 || teamsFilter.includes(player.team_name)
    const positionInFilter =
      positionFilter.length === 0 || positionFilter.includes(player.position)
    const priceInRange =
      player.now_cost >= minPrice && player.now_cost <= maxPrice
    const ownershipInRange =
      player.selected_by_percent >= minOwnership &&
      player.selected_by_percent <= maxOwnership
    return teamInFilter && positionInFilter && priceInRange && ownershipInRange
  })

  return filteredPlayers
}

export {
  getPlayers,
  checkIfPlayerExists,
  checkIfPositionAvailable,
  calculateNewInBankAfterAddingPlayer,
  calculateNewInBankAfterTransferringPlayer,
  calculateNewInBankAfterSellingPlayer,
  calculateNewInBankAfterRetainingPlayer,
  calculateNewTeamValueAfterAddingPlayer,
  calculateNewTeamValueAfterTransferringPlayer,
  calculateNewInBankAfterRemovingPlayer,
  calculateNewTeamValueAfterRemovingPlayer,
  calculateNewTeamAvgOwnershipAfterAddingPlayer,
  calculateNewTeamAvgOwnershipAfterTransferringPlayer,
  calculateNewTeamAvgOwnershipAfterRemovingPlayer,
  findPositionWithMinimumPlayersInTheMainTeam,
  calculateTransfersLeftTransfersCost,
  filterPlayers
}
