import { useDraftsSharedState } from 'state/DraftsSharedContext'
import { useAppSharedState } from 'state/AppSharedContext'
import { getPlayers } from 'utils/draft'
import Box from '@mui/material/Box'
import PlayerBox from 'components/player_box/PlayerBox'
import green from '@mui/material/colors/green'
import grey from '@mui/material/colors/grey'
import Typography from '@mui/material/Typography'
import CONFIG from 'config.json'

export default function Subs () {
  const { selectedDraft, draftsList } = useDraftsSharedState()
  const { fplTeamId } = useAppSharedState()

  const subs = getPlayers({ draftTeam: selectedDraft, inMainTeam: [false] })

  return (
    <>
      {/* {fplTeamId && draftsList.length > 0 && (
        <Typography
          variant='body2'
          sx={{
            fontWeight: '600',
            padding: '0.1em',
            backgroundColor: green[300],
            color: grey[900],
            textAlign: 'center'
          }}
        >
          Bench
        </Typography>
      )} */}
      <Box
        className='TeamFieldPanel__Subs'
        justifyContent='space-evenly'
        alignItems='center'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: green[300],
          borderBottomLeftRadius: '0.5em',
          borderBottomRightRadius: '0.5em',
          borderTop: '2px solid grey',
          height: { xs: '110px', sm: '160px', md: '160px' },
          width: { xs: '94vw', sm: '94vw', md: '750px' }
        }}
      >
        {fplTeamId &&
          subs.map(player => (
            <PlayerBox
              key={player.positionId}
              playerInfo={player}
              size={
                subs.length === 5
                  ? 'small'
                  : subs.length === 4
                  ? 'medium'
                  : 'large'
              }
            />
          ))}
      </Box>
    </>
  )
}
