import Box from '@mui/material/Box'
import TeamSelector from './TeamSelector'
import PositionsSelector from './PositionsSelector'
import PreBuiltFiltersSelect from './PreBuiltFiltersSelect'
import StatSelector from './StatSelector'
import PriceMinMaxInput from './PriceMinMaxInput'
import OwnershipRangeInput from './OwnershipRangeInput'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import { useAppSharedState } from 'state/AppSharedContext'
import { FormControlLabel, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { grey } from '@mui/material/colors'

export default function PlayersFilterSearchBox () {
  const { gameweekInfo } = useAppSharedState()
  const {
    setPriceSliderValue,
    setPlayersTableApplyTrigger,
    setTeamsSelectedForPlayersTable,
    setPositionsSelectedForPlayersTable,
    nonInjuredPlayersOnlyForPlayersTable,
    setNonInjuredPlayersOnlyForPlayersTable,
    showPlayersWithHighXminsForPlayersTable,
    setShowPlayersWithHighXminsForPlayersTable
  } = useDraftsSharedState()

  const handleApply = () => {
    setPlayersTableApplyTrigger(true)
  }

  const handleReset = () => {
    setPriceSliderValue([
      gameweekInfo.min_player_price,
      gameweekInfo.max_player_price
    ])
    setTeamsSelectedForPlayersTable([])
    setPositionsSelectedForPlayersTable([])
    setNonInjuredPlayersOnlyForPlayersTable(true)
    setShowPlayersWithHighXminsForPlayersTable(true)
    setPlayersTableApplyTrigger(true)
  }

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: { xs: 'wrap', sm: 'wrap', md: 'wrap' },
          alignItems: 'space-evenly',
          alignContent: 'space-evenly',
          justifyItems: 'space-evenly',
          justifyContent: 'space-evenly',
          gap: { xs: '4px', sm: '4px', md: '4px' }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            alignContent: 'center',
            justifyItems: 'center',
            justifyContent: 'center',
            // border: '1px solid grey',
            borderRadius: '0.5em',
            padding: { xs: '2px', sm: '4px', md: '4px' },
            gap: { xs: '2px', sm: '8px', md: '8px' },
            margin: { xs: '2px', sm: '4px', md: '4px' }
          }}
        >
          {/* <PreBuiltFiltersSelect /> */}

          <TeamSelector />
          <PositionsSelector />
          <PriceMinMaxInput />
          <OwnershipRangeInput />
        </Box>

        {/* <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={nonInjuredPlayersOnlyForPlayersTable}
                onChange={e =>
                  setNonInjuredPlayersOnlyForPlayersTable(e.target.checked)
                }
              />
            }
            label='Hide injured and suspended players'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showPlayersWithHighXminsForPlayersTable}
                onChange={e =>
                  setShowPlayersWithHighXminsForPlayersTable(e.target.checked)
                }
              />
            }
            label='Hide players with '
          />
        </Box> */}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            justifyItems: 'center',
            justifyContent: 'center',
            // border: '1px solid grey',
            borderRadius: '0.5em',
            padding: { xs: '0px', sm: '4px', md: '4px' },
            margin: { xs: '0px', sm: '4px', md: '4px' }
          }}
        >
          <StatSelector />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          margin: { xs: '2px', sm: '4px', md: '4px' }
        }}
      >
        <ButtonGroup
          variant='contained'
          size='small'
          aria-label='Basic button group'
        >
          {/* <Button
            variant='contained'
            onClick={handleApply}
            sx={{
              fontSize: { xs: '12px', sm: '16px', md: '16px' }
              // padding: '0.5em'
            }}
          >
            Show Rankings
          </Button> */}
          <Button
            variant='outlined'
            onClick={handleReset}
            sx={{
              fontSize: { xs: '12px', sm: '16px', md: '16px' }
              // padding: '0.5em'
            }}
          >
            Reset Filters
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  )
}
