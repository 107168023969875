import { Typography } from '@mui/material'
import { useAppSharedState } from 'state/AppSharedContext'
import { useDraftsSharedState } from 'state/DraftsSharedContext'
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import green from '@mui/material/colors/green'
import red from '@mui/material/colors/red'
import grey from '@mui/material/colors/grey'
import CONFIG from 'config.json'
import { original } from '@reduxjs/toolkit'

// const fdrBgColorMapping = {
//   1: green[900],
//   2: green[500],
//   3: grey[400],
//   4: red[400],
//   5: red[700]
// }
// const fdrTextColorMapping = {
//   1: 'white',
//   2: 'black',
//   3: 'black',
//   4: 'white',
//   5: 'white'
// }

export default function ExpectedPointsNextGameweeks ({
  fixtures,
  numOfGameweeks,
  size
}) {
  const { gameweekInfo } = useAppSharedState()
  const { originalPlayersData, selectedDraft } = useDraftsSharedState()
  const [playerFixturesByGameweekMap, setPlayerFixturesByGameweekMap] =
    useState({})

  useEffect(() => {
    if (fixtures === undefined) {
      return
    }

    if (originalPlayersData.length === 0) {
      return
    }
    const nextGameweek = gameweekInfo.next_gw.id
    const playerFixtures = fixtures?.filter(
      fixture =>
        fixture.gameweek_num >= nextGameweek &&
        fixture.gameweek_num < nextGameweek + numOfGameweeks
    )
    if (playerFixtures === undefined) {
      return
    }
    setPlayerFixturesByGameweekMap(
      playerFixtures.reduce((acc, fixture) => {
        if (acc[fixture.gameweek_num]) {
          acc[fixture.gameweek_num].push(fixture)
        } else {
          acc[fixture.gameweek_num] = [fixture]
        }
        return acc
      }, {})
    )
  }, [gameweekInfo, originalPlayersData, selectedDraft])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        // border: '1px solid black',
        borderRadius: '0.4em',
        backgroundColor: grey[900],
        margin: {
          xs: '0.05em 0.05em',
          sm: '0.05em 0.05em',
          md: '0.05em 0.05em'
        },
        width: { xs: '100%', sm: '100%', md: '100%' },
        // height: { xs: '26px', sm: '40px', md: '42px' },
        padding: { xs: '0.2px', sm: '0.5px', md: '1px' },
        // flexWrap: 'wrap',
        justifyContent: numOfGameweeks === 3 ? 'space-between' : 'center'
      }}
    >
      {playerFixturesByGameweekMap &&
        Object.keys(playerFixturesByGameweekMap).map(gameweekNum => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: grey[900],
              borderRadius: '0.4em',
              padding: {
                xs: '0.1em 0em',
                sm: '0.1em 0.2em',
                md: '0.1em 0.2em'
              },
              margin: {
                xs: '0.05em 0.1em',
                sm: '0.05em 0.1em',
                md: '0.05em 0.2em'
              }
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '2px' }}>
              <Typography
                align='center'
                sx={{
                  fontSize: {
                    xs:
                      numOfGameweeks === 3 && size === 'small'
                        ? '6px'
                        : numOfGameweeks === 3 && size === 'medium'
                        ? '8px'
                        : numOfGameweeks === 1
                        ? '9px'
                        : '8px',
                    sm:
                      numOfGameweeks === 3 && size === 'small'
                        ? '12px'
                        : numOfGameweeks === 3 && size === 'medium'
                        ? '12px'
                        : numOfGameweeks === 1
                        ? '12px'
                        : '12px',
                    md:
                      numOfGameweeks === 3 && size === 'small'
                        ? '12px'
                        : numOfGameweeks === 3 && size === 'medium'
                        ? '12px'
                        : numOfGameweeks === 1
                        ? '12px'
                        : '12px'
                  },
                  // fontWeight: 'bold',
                  color: grey[400],
                  maxWidth: '40px',
                  textWrap: 'nowrap',
                  alignContent: 'center'
                }}
              >
                {playerFixturesByGameweekMap[gameweekNum].length === 2 ? (
                  <strong>DGW{gameweekNum}</strong>
                ) : (
                  'GW' + gameweekNum
                )}
              </Typography>
            </Box>

            <Typography
              align='center'
              sx={{
                fontSize: { xs: '8px', sm: '12px', md: '14px' },
                fontWeight: 'bold',
                color:
                  (playerFixturesByGameweekMap[gameweekNum]
                    .map(fixture => fixture.xP)
                    .reduce((a, b) => a + b) <= CONFIG.range.form.poor &&
                    red[500]) ||
                  (playerFixturesByGameweekMap[gameweekNum]
                    .map(fixture => fixture.xP)
                    .reduce((a, b) => a + b) > CONFIG.range.form.poor &&
                    playerFixturesByGameweekMap[gameweekNum]
                      .map(fixture => fixture.xP)
                      .reduce((a, b) => a + b) <= CONFIG.range.form.avg &&
                    grey[100]) ||
                  (playerFixturesByGameweekMap[gameweekNum]
                    .map(fixture => fixture.xP)
                    .reduce((a, b) => a + b) > CONFIG.range.form.avg &&
                    green[400]),

                alignContent: 'center'
              }}
            >
              {playerFixturesByGameweekMap[gameweekNum]
                .map(fixture => fixture.xP)
                .reduce((a, b) => a + b)}
            </Typography>
          </Box>
        ))}
    </Box>
  )
}
