import React from 'react'
import { Navigate } from 'react-router-dom'

export default function ProtectedRoute ({ children }) {
  const auth = JSON.parse(localStorage.getItem('auth'))
  const isGuest = auth && auth.user && auth.user.is_guest
  var isAuthenticated = false
  if (isGuest) {
    isAuthenticated = auth && auth.user
  } else {
    isAuthenticated =
      auth && auth.user && auth.user.is_verified && auth.user.email
  }

  return isAuthenticated ? <>{children}</> : <Navigate to='/login/' />
}
