import React from 'react'
import { Box, Typography, Button } from '@mui/material'

export default function Features () {
  return (
    <Box
      id='features'
      sx={{
        padding: { xs: 2, md: 4 },
        bgcolor: 'background.paper',
        textAlign: 'center'
      }}
    >
      {/* Headline */}
      <Typography
        variant='h3'
        component='h3'
        gutterBottom
        sx={{ fontWeight: 'bold', marginBottom: 4, color: 'primary.main' }}
      >
        Our Key Features
      </Typography>

      {/* Feature Cards */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'center',
          gap: 4
        }}
      >
        {/* Feature 1 */}
        <Box
          sx={{
            flex: 1,
            bgcolor: 'secondary.light',
            borderRadius: 2,
            padding: 4,
            boxShadow: 2
          }}
        >
          <Typography
            variant='h4'
            sx={{ fontWeight: 'bold', color: '#1e1e2f', padding: 2 }}
          >
            Drafts Planner
          </Typography>
          <Typography variant='h5' sx={{ color: '#666', marginBottom: 2 }}>
            Plan your drafts effectively with our intuitive drafts planner.
            View your players important data points. Make transfers. And more!
          </Typography>
          {/* <Button variant='contained' sx={{ textTransform: 'none' }}>
            Try Now →
          </Button> */}
        </Box>

        <Box
          sx={{
            flex: 1,
            bgcolor: 'secondary.light',
            borderRadius: 2,
            padding: 4,
            boxShadow: 2
          }}
        >
          <Typography
            variant='h4'
            sx={{ fontWeight: 'bold', color: '#1e1e2f', padding: 2 }}
          >
            Players Rankings
          </Typography>
          <Typography variant='h5' sx={{ color: '#666', marginBottom: 2 }}>
            Use expected points and players stats to make informed decisions.
            Expected points are calculated for upto 5 GW using an extensively
            trained model.
          </Typography>

          {/* <Typography
            variant='h6'
            sx={{ color: 'primary.dark', marginBottom: 2 }}
          >
            Coming soon
          </Typography> */}
        </Box>

        {/* Feature 2 */}
        {/* <Box
          sx={{
            flex: 1,
            bgcolor: 'secondary.light',
            borderRadius: 2,
            padding: 4,
            boxShadow: 2
          }}
        >
          <Typography
            variant='h4'
            sx={{ fontWeight: 'bold', color: '#1e1e2f', padding: 2 }}
          >
            Templates
          </Typography>
          <Typography variant='h5' sx={{ color: '#666', marginBottom: 2 }}>
            Get started quickly and easily with our ready-to-use templates.
          </Typography>

          <Typography
            variant='h6'
            sx={{ color: 'primary.dark', marginBottom: 2 }}
          >
            Coming soon
          </Typography>
        </Box> */}

        {/* Feature 3 */}
        <Box
          sx={{
            flex: 1,
            bgcolor: 'secondary.light',
            borderRadius: 2,
            padding: 4,
            boxShadow: 2
          }}
        >
          <Typography
            variant='h4'
            sx={{ fontWeight: 'bold', color: '#1e1e2f', padding: 2 }}
          >
            AI Assistant
          </Typography>
          <Typography variant='h5' sx={{ color: '#666', marginBottom: 2 }}>
            We believe your creativity and experience as a FPL manager is
            invaluable. But AI as a tool can give an edge. Our AI assists you
            with data, strategies and sharp insights. In the end, you make
            decisions.
          </Typography>
          <Typography
            variant='h6'
            sx={{ color: 'primary.dark', marginBottom: 2 }}
          >
            Coming soon
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
