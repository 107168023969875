import { useState } from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import PlayersPanel from 'components/players_panel/PlayersPanel'
import TeamFixturesPanel from 'components/teams_fixtures_panel/TeamsFixturesPanel'
import TeamFieldPanel from 'components/team_field_panel/TeamFieldPanel'
import PlayersRankings from 'components/players_panel/PlayersRankings'
import { useTheme } from '@mui/material'
import { useDraftsSharedState } from 'state/DraftsSharedContext'

export default function PlayersTeamsPanel () {
  const theme = useTheme()
  const { currentRightPanelTab, setCurrentRightPanelTab } =
    useDraftsSharedState()

  const handleChange = (event, newValue) => {
    setCurrentRightPanelTab(newValue)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh', // Full viewport height
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        justifyItems: 'center',
        flexGrow: {
          xs: 1, // Allow to grow for small screens
          sm: 1, // Allow to grow for medium screens
          md: 0.2, // Use less space for larger screens
          lg: 0.2,
          xl: 0.2
        },
        // marginBottom: '60px',
        width: '100%'
      }}
    >
      {/* Tab Headers */}
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'center' // Center the TabList
        }}
      >
        <TabContext value={currentRightPanelTab}>
          <TabList onChange={handleChange}>
            <Tab sx={{ display: { md: 'none' } }} label='Draft' value='draft' />
            <Tab label='Players Rankings' value='players_rankings' />
          </TabList>
        </TabContext>
      </Box>

      {/* Scrollable Content */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          overflowY: 'auto', // Enables vertical scrolling
          paddingBottom: '100px',
          width: '100%',
          '&::-webkit-scrollbar': {
            width: '8px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.main, // Thumb color
            borderRadius: '4px'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: theme.palette.primary.dark // Thumb hover color
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.background.default // Track color
          },
          scrollbarWidth: 'thin', // For Firefox
          scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.default}` // Thumb and track colors for Firefox
        }}
      >
        <TabContext value={currentRightPanelTab}>
          <TabPanel
            sx={{
              width: '100%',
              padding: { xs: '4px', sm: '4px', md: '8px' },
              justifyItems: 'center'
            }}
            value='draft'
          >
            <TeamFieldPanel />
          </TabPanel>
          <TabPanel
            sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: '98%',
                lg: '98%',
                xl: '98%'
              },
              padding: {
                xs: '4px',
                sm: '4px',
                md: '0px',
                lg: '0px',
                xl: '0px'
              },
              justifyItems: 'center'
            }}
            value='players_rankings'
          >
            <PlayersRankings />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  )
}
