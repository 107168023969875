import React from 'react'
import { Box, Typography } from '@mui/material'

export default function PersonalStoryHeadline () {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: { xs: 2, md: 4 },
        bgcolor: 'background.paper'
      }}
    >
      {/* Main Headline */}
      <Typography
        variant='h2'
        component='h2'
        gutterBottom
        sx={{
          fontWeight: 'bold',
          fontSize: { xs: '1.75rem', md: '3rem' },
          color: 'primary.main'
        }}
      >
        Why We Built FPL Garage
      </Typography>

      {/* Subtitle */}
      <Typography
        variant='body1'
        component='p'
        gutterBottom
        sx={{
          fontSize: { xs: '1rem', md: '1.25rem' },
          color: 'text.primary',
          maxWidth: '800px',
          marginTop: 2
        }}
      >
        FPL Garage was born out of a passion for Fantasy Premier League. We’ve
        all experienced the joy of picking the perfect captain, the frustration
        of benching a haul, and the endless debates over transfers. FPL Garage
        is our way of turning this passion into a platform that empowers every
        FPL manager to make smarter, data-driven decisions.
      </Typography>

      {/* Additional Motivation */}
      <Typography
        variant='body1'
        component='p'
        sx={{
          fontSize: { xs: '1rem', md: '1.25rem' },
          color: 'text.primary',
          maxWidth: '800px',
          marginTop: 2
        }}
      >
        This is a dream. A proclaimation to create the best ever FPL experience.
        Whether you are a FPL expert, content-creator or beginner - FPL garage is the place for you.
      </Typography>
    </Box>
  )
}
